import React from 'react';
import Button from '@mui/material/Button';
import { useRecordContext } from 'react-admin';

const QRCode = require('qrcode');

const drawCirlWithCross = (ctx, x, y, r) => {
  ctx.beginPath();
  ctx.arc(x, y, r, 0, Math.PI * 2, true);
  ctx.lineWidth = 4;
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(x, y - r);
  ctx.lineTo(x, y + r);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(x - r, y);
  ctx.lineTo(x + r, y);
  ctx.stroke();
};

const handleClick = async (record) => {

  const code = record.companySite.site.siteCode;
  const width = 1024;
  var qrCanvas = document.createElement('canvas');
  await QRCode.toCanvas(
    qrCanvas,
    "https://app.locspect.com/surveys/sites?surveySiteId=" + record.id,
    { width: width, errorCorrectionLevel: 'Q' }
  );

  var ctx = qrCanvas.getContext('2d');
  ctx.font = '40px bold';
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center';
  ctx.fillText("Survey".toUpperCase(), width / 2, 50);

  var textWidth = ctx.measureText(code.toUpperCase()).width;
  if (textWidth > 1024) {
    ctx.font = '20px bold';
  } else if (textWidth > 800) {
    ctx.font = '26px bold';
  } else {
    ctx.font = '30px bold';
  }
  ctx.fillText(code.toUpperCase(), width / 2, width - 45);

  ctx.beginPath();
  ctx.rect(10, 10, width - 20, width - 20);
  ctx.lineWidth = 7;
  ctx.strokeStyle = 'black';
  ctx.stroke();

  const radius = 20;
  const startX = 45;
  const startY = 45;
  drawCirlWithCross(ctx, startX, startY, radius);
  drawCirlWithCross(ctx, width - startX, startY, radius);
  drawCirlWithCross(ctx, startX, width - startY, radius);
  drawCirlWithCross(ctx, width - startX, width - startY, radius);



  const image = new Image();
  image.onload = () => {
    ctx.drawImage(image, (width / 2) - 50, (width / 2) - 50, 100, 100);
    var link = document.createElement('a');
    link.download = code + '_survey.png';
    link.href = qrCanvas.toDataURL();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  image.src = "cbs_logo.png";
};

const SurveyQRCodeButton = () => {
  const record = useRecordContext();
  return (
    <Button size="small" variant="outlined" onClick={() => handleClick(record)}>
      QR
    </Button>
  );
};

export default SurveyQRCodeButton;
