import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { FunctionField } from "react-admin";

const DurationField = ({ source, source2 }) => {
    return <FunctionField
        render={record => {
            const timestamp1 = _.get(record, source, null);
            const timestamp2 = _.get(record, source2, null);
            return `${timestamp1 ? moment.duration(moment(timestamp1).diff(moment(timestamp2))).asHours().toFixed(2) + " Hrs" : ""}`
        }
        }
    />
};

export default DurationField;