import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { LOCSPECT_API_URL } from "../config/config";

const CompanyApi = {
    getCompanies: async (token) => {
        const userCompaniesResult = await fetch(LOCSPECT_API_URL + '/v1/users/companies?web=true', {
            headers: {
                Authorization: "Bearer " + token,
                Accept: 'application/json',
                'trackingNumber': uuidv4()
            }
        });

        if (userCompaniesResult.ok) {
            const userCompanies = await userCompaniesResult.json();
            if (!_.isEmpty(userCompanies)) {
                return userCompanies; 
            }
            return Promise.reject({status: userCompaniesResult.status, body: "No Company Linked"});
        }
        return Promise.reject({status: userCompaniesResult.status, body: userCompaniesResult.json().catch(e => "Something is wrong?")});
    },
    getManagedCompanies: async(token, companyId) => {
        const managedCompaniesResult = await fetch(LOCSPECT_API_URL + '/v1/companies/' + companyId + '/companies', {
            headers: {
                Authorization: "Bearer " + token,
                Accept: 'application/json',
                'trackingNumber': uuidv4()
            }
        });

        if (managedCompaniesResult.ok) {
            const managedCompanies = await managedCompaniesResult.json();
            if (!_.isEmpty(managedCompanies)) {
                return managedCompanies; 
            }
            return Promise.reject({status: managedCompaniesResult.status, body: "Can't find managed companies"});
        }
        return Promise.reject({status: managedCompaniesResult.status, body: managedCompaniesResult.json().catch(e => "Something is wrong?")});
        
    },
    getSharedCompanies: async(token, companyId) => {
        const sharedCompaniesResult = await fetch(LOCSPECT_API_URL + '/v1/companies/companies/' + companyId, {
            headers: {
                Authorization: "Bearer " + token,
                Accept: 'application/json',
                'trackingNumber': uuidv4()
            }
        });

        if (sharedCompaniesResult.ok) {
            const sharedCompanies = await sharedCompaniesResult.json();
            if (!_.isEmpty(sharedCompanies)) {
                return sharedCompanies; 
            }
            return Promise.reject({status: sharedCompaniesResult.status, body: "Can't find managed companies"});
        }
        return Promise.reject({status: sharedCompaniesResult.status, body: sharedCompaniesResult.json().catch(e => "Something is wrong?")});
        
    }
};

export default CompanyApi;