import React, { useState } from 'react';
import { GeoJson, Map, ZoomControl } from 'pigeon-maps';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { makeStyles } from '@mui/styles';
import indigenousMapGeoJson from './indigenous_map.json';
import Switch from '@mui/material/Switch';
import { FormControlLabel, Tooltip } from '@mui/material';
import KingBedOutlinedIcon from '@mui/icons-material/KingBedOutlined';
import PlaceIcon from '@mui/icons-material/Place';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: 'center',
  },
  cost: {
    marginRight: '1em',
    color: theme.palette.text.primary,
  },
}));

const getRoleColor = (role) => {
  if (role === 'INSPECTION') {
    return 'blue';
  }
  return 'green';
}
const createMarker = (mark, seenDataRole, seen, greenMarkers, TooltipMarker) => {
  const key = mark.id + mark.role;
  if (!(key in seenDataRole)) {
    seen[mark.id] = true;
    seenDataRole[key] = true;
    greenMarkers.push(<TooltipMarker key={key} name={mark.name} anchor={mark.loc}><PlaceIcon sx={{
      ":hover": {
        fontSize: 40,
        borderRadius: '75%',
        bgcolor: "#bababa"
      }, fontSize: 35, color: getRoleColor(mark.role)
    }}></PlaceIcon></TooltipMarker>);
  }
}

const MapChart = (props) => {
  const classes = useStyles();
  const seen = {};
  const greenMarkers = [];
  const blueMarkers = [];
  const yellowMarkers = [];
  const redMarkers = [];
  const [divMarkerData, setDivMarkerData] = useState(undefined);
  const [indeniousCheck, setIndeniousCheck] = useState(false);
  const [indeniousData] = useState({
    "type": "FeatureCollection",
    "features": indigenousMapGeoJson
  });


  const TooltipMarker = ({ name, left, top, style, children }) => (
    <div style={{
      position: 'absolute',
      left: left,
      top: top,
      ...(style || {})
    }}>
      <Tooltip title={<h2>{name}</h2>} arrow>
        {children}
      </Tooltip>
    </div>
  )
  const seenDataRole = {}

  if (props.data) {
    props.data.forEach(mark => {
      if(mark.role === 'INSPECTION') {
        createMarker(mark, seenDataRole, seen, blueMarkers, TooltipMarker);
      }
    });
  }

  if (props.data) {
    props.data.forEach(mark => {
      if(mark.role === 'CLEANER') {
        createMarker(mark, seenDataRole, seen, greenMarkers, TooltipMarker);
      }
    });
  }

  if (props.extraMarkers) {
    props.extraMarkers.forEach(mark => {
      if (!(mark.id in seen)) {
        seen[mark.id] = true;
        yellowMarkers.push(<TooltipMarker key={mark.id} name={mark.name} anchor={mark.loc}><PlaceIcon sx={{
          ":hover": {
            fontSize: 40,
            borderRadius: '75%',
            bgcolor: "#bababa"
          }, fontSize: 35, color: "red"
        }}></PlaceIcon></TooltipMarker>);
      }
    });
  }

  if (props.mockData) {
    props.mockData.forEach(mark => {
      if (!(mark.id in seen)) {
        seen[mark.id] = true;
        redMarkers.push(<TooltipMarker key={mark.id} name={mark.name} anchor={mark.loc}><KingBedOutlinedIcon sx={{
          ":hover": {
            fontSize: 40,
            borderRadius: '75%',
            bgcolor: "#bababa"
          }, fontSize: 35, color: "orange"
        }}></KingBedOutlinedIcon></TooltipMarker>);
      }
    });
  }

  const DivMarker = ({ left, top, style, children }) => (
    <div style={{
      position: 'absolute',
      left: left,
      top: top,
      ...(style || {})
    }}>{children}</div>
  )

  const markers = redMarkers.concat(yellowMarkers, greenMarkers, blueMarkers);

  const findCenter = (polygon) => {
    if ('geometry' in polygon) polygon = polygon.geometry;
    if (!Array.isArray(polygon)) polygon = polygon.coordinates[0];

    let minx = 1000;
    let miny = 1000;
    let maxx = -1000;
    let maxy = -1000;

    for (var i = 0; i < polygon.length; i++) {
      var point = polygon[i]
      var x = point[0]
      var y = point[1]

      if (x < minx) minx = x
      else if (x > maxx) maxx = x
      if (y < miny) miny = y
      else if (y > maxy) maxy = y
    }
    return [
      minx + ((maxx - minx) / 2),
      miny + ((maxy - miny) / 2)
    ];
  }

  return (
    <Card className={classes.root}>

      <CardHeader title={props.title} action={
        <FormControlLabel control={<Switch checked={indeniousCheck} />} label="Indigenous Map" onChange={(event, checked) => {
          setIndeniousCheck(checked);
        }} />
      }></CardHeader>


      <Map height={800} defaultCenter={[52.9, -101.671547]} defaultZoom={4} >

        {indeniousCheck && indeniousData && <GeoJson data={indeniousData}
          styleCallback={(feature, hover) => {
            if (hover) {
              return {
                fill: feature.properties.color + '30',
                strokeWidth: "1",
                stroke: "black",
                r: "20",
              };
            }
            return {
              fill: "#00000000",
              strokeWidth: "0.1",
              stroke: "black",
              r: "20",
            };
          }}
          onMouseOver={({ event, anchor, payload }) => {
            setDivMarkerData({
              text: payload.properties.Name,
              anchor: findCenter(payload.geometry.coordinates[0])
            })
          }}
          onMouseOut={() => {
            setDivMarkerData(undefined);
          }}
        />}
        {markers}
        {divMarkerData && <DivMarker anchor={[divMarkerData.anchor[1], divMarkerData.anchor[0]]}>{divMarkerData.text}</DivMarker>}
        <ZoomControl />
      </Map>
    </Card>
  );
};

export default MapChart;


