const AuthProvider = {
    login: async () => {
        return Promise.resolve();
    },
    logout: () => {
        return Promise.resolve();
    },
    checkError: () => {
        return Promise.resolve();
    },
    checkAuth: () => {
        return Promise.resolve();
    },
    getPermissions: () => {
        return Promise.resolve();
    },
};

export default AuthProvider;