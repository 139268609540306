import { TranslationMessages } from 'ra-core';

export const fr: TranslationMessages = {
  "locspect": {
    "resource": {
      "menu": {
        "company": "Entreprise",
        "managedCompany": "Entreprise Gérée",
        "sharedCompany": "Entreprise Partagée",
        "signOut": "Déconnexion",
        "statistics": "Statistiques",
        "back": "Retour"
      },
      "employees": {
        "label": 'Employés',
        "fields": {
          "firstName": 'Prénom',
          "lastName": 'Nom de famille',
          "email": 'Email',
          "phone": 'Téléphone',
          "role": 'Rôle',
          "eRailSafe": 'eRailSafe',
        },
      },
      "sites": {
        "label": 'Sites',
        "fields": {
          "code": 'Code',
          "siteName": 'Nom du site',
          "street": 'Rue',
          "city": 'Ville',
          "state": 'État',
          "country": 'Pays',
          "zip": 'Code postal',
          "latitude": 'Latitude',
          "longitude": 'Longitude',
        },
      },
      "workOrders": {
        "label": "Demande de service",
        "fields": {
          "number": "Numéro",
          "companySite": "Site",
          "company": "Entreprise",
          "representativeName": "Demandeur",
          "createdAt": "Créé le",
          "updatedAt": "Mis à jour le",
          "status": "Statut de la demande de service",
          "type": "Type de demande de service",
          "poWoNumber": "Numéro PO/WO",
          "costCenter": "Centre de coûts",
          "requestedStartDate": "Date de demande",
          requestedStartDateAfter: "Avant la Date de demande",
          requestedStartDateBefore: "Avant la Date de demande",
          assignedTo: "Assigné à",
          "startDate": "Date de début",
          "endDate": "Date de fin",
          "createdBy": "Créateur",
          "updatedBy": "Mise à jour par",
          "beforePicture": "Avant l'image",
          "afterPicture": "Après l'image",
          "firstName": "Prénom",
          "lastName": "Nom de famille",
          "rate": "Taux horaire",
          "totalCost": "Coût total",
          "employee": "Employé",
          "materialName": "Nom",
          "materialPrice": "Prix",
          "materialQuantity": "Quantité",
          "materialTotal": "Prix total",
          "description": "Description",
          "siteName": 'Nom du site',
          "street": 'Rue',
          "city": 'Ville',
          "state": 'État',
          "country": 'Pays',
          "zip": 'Code postal',
          "statusInput": {
            "OPEN": "Ouvert",
            "CLOSED": "Fermé"
          },
          "typeInput": {
            "NEW_REQUEST": "Nouvelle demande",
            "CHANGE": "Changement",
            "PERIODICAL": "Périodique",
            "COMPLAINT_ACTION_REQUIRED": "Plainte/Action requise"
          },
          "history": "Historique",
          "print": "Imprimer"
        }
      },
      "assessments": {
        "label": 'Évaluation',
        "survey": 'Enquête',
        "inspection": 'Inspection',
        "fields": {
          "assessmentType": 'Type d\'évaluation',
          "assessment": 'Nom',
          "questions": 'Questions',
          "question": 'Question',
          "required": 'Obligatoire',
          "allowAllCompSites": 'Autoriser tous les sites gérés',
          "extraDetails": 'Autoriser les commentaires et les images supplémentaires',
          "answerType": {
            "label": 'Type de réponse',
            "RADIO_SELECT_5": 'Évaluation de 1 à 5',
            "RADIO_SELECT_YES_NO": 'Oui ou Non',
            "CHECK_BOX": 'Case à cocher',
            "SINGLE_LINE_TEXT": 'Texte en une ligne',
            "MULTI_LINE_TEXT": 'Texte sur plusieurs lignes',
          },
        },
      },
      "assessmentSites": {
        "label": 'Sites',
        "fields": {
          "assessment": 'Nom',
          "companyName": 'Entreprise',
          "code": 'Code',
          "siteName": 'Nom du site',
          "street": 'Rue',
          "city": 'Ville',
          "state": 'État',
          "country": 'Pays',
          "zip": 'Code postal',
          "managedSite": 'Site géré'
        },
      },
      "assessmentResults": {
        "label": 'Résultats',
        "fields": {
          "assessment": 'Nom',
          "companyName": 'Entreprise',
          "code": 'Code',
          "siteName": 'Nom du site',
          "createdAt": 'Créé le',
          "assessmentQuestion": 'Question d\'évaluation',
          "answer": 'Réponse',
        },
      },
      "assessmentUsercompanyResults": {
        "label": 'Résultats évaluation',
        "fields": {
          "assessment": 'Nom',
          "firstName": 'Prénom',
          "lastName": 'Nom de famille',
          "createdAt": 'Créé le',
          "assessmentQuestion": 'Question d\'évaluation',
          "answer": 'Réponse',
        },
      },
      "sharedSites": {
        "label": 'Sites',
        "fields": {
          "code": 'Code',
          "siteName": 'Nom du site',
          "companyName": 'Nom de l\'entreprise',
          "cleanUpDate": 'Dernière date de nettoyage',
          "cleanUpDateStart": 'Date de début de nettoyage',
          "cleanUpDateEnd": 'Date de fin de nettoyage',
          "state": 'État',
          "country": 'Pays',
          "role": 'Rôle',
        },
      },
      "sharedSiteJobs": {
        "label": 'Travaux',
        "fields": {
          "code": 'Code',
          "siteName": 'Nom du site',
          "companyName": 'Nom de l\'entreprise',
          "startDate": 'Date de début',
          "startTime": 'Heure de début',
          "startTimeStart": 'Début de l\'heure de début',
          "startTimeEnd": 'Fin de l\'heure de début',
          "endDate": 'Date de fin',
          "endTime": 'Heure de fin',
          "endTimeStart": 'Début de l\'heure de fin',
          "endTimeEnd": 'Fin de l\'heure de fin',
          "period": 'Période',
          "duration": 'Durée',
          "state": 'État',
          "country": 'Pays',
          "role": 'Rôle',
        },
      },
      "managedSites": {
        "label": 'Sites',
        "fields": {
          "code": 'Code',
          "siteName": 'Nom du site',
          "companyName": 'Nom de l\'entreprise',
          "cleanUpDate": 'Dernière date de nettoyage',
          "cleanUpDateStart": 'Date de début de nettoyage',
          "cleanUpDateEnd": 'Date de fin de nettoyage',
          "street": 'Rue',
          "city": 'Ville',
          "state": 'État',
          "country": 'Pays',
          "zip": 'Code postal',
          "role": 'Rôle',
          "managedCompany": 'Entreprise gérée',
          "jobCount": 'Nombre de travaux',
          "frequency": 'Fréquence',
          "frequencyType": 'Type de fréquence',
        },
      },
      "managedSiteJobs": {
        "label": 'Travaux',
        "fields": {
          "code": 'Code',
          "siteName": 'Nom du site',
          "companyName": 'Nom de l\'entreprise',
          "startDate": 'Date de début',
          "startTime": 'Heure de début',
          "startTimeStart": 'Début de l\'heure de début',
          "startTimeEnd": 'Fin de l\'heure de début',
          "endDate": 'Date de fin',
          "endTime": 'Heure de fin',
          "endTimeStart": 'Début de l\'heure de fin',
          "endTimeEnd": 'Fin de l\'heure de fin',
          "duration": 'Durée (H)',
          "period": 'Période',
          "distance": 'Distance de l\'employé (KM)',
          "employeeName": 'Nom de l\'employé',
          "firstName": 'Prénom',
          "lastName": 'Nom de famille',
          "state": 'État',
          "country": 'Pays',
          "role": 'Rôle',
          "eRailSafe": 'eRailSafe',
        },
      },
      "managedSiteAlerts": {
        "label": 'Alertes',
        "fields": {
          "code": 'Code',
          "siteName": 'Nom du site',
          "companyName": 'Nom de l\'entreprise',
          "status": 'Statut',
          "alertDate": 'Date',
          "alertDateStart": 'Début de la date d\'alerte',
          "alertDateEnd": 'Fin de la date d\'alerte',
          "state": 'État',
          "country": 'Pays',
          "role": 'Rôle',
        },
      },
    },
  },
};
