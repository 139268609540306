import React, { useState, useEffect, useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import Grid from '@mui/material/Grid';
import PieCard from '../card/PieCard';
import moment from 'moment';
import MarkerMap from '../map/MarkerMaps';
import LineChartCard from '../card/LineChartCard';
import {
  calculateCompanySiteAnalytics,
  calculateCompanySiteJobAnalytics,
  calculateUserCompanyAnalytics,
  createLineChartData,
  extractMapDatafromCompanySiteJobAnalytics,
  sortObjectByKeys,
} from '../service/Analytics';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import _ from 'lodash';
import { COUNTRIES, STATES } from '../config/locations';
import { ObjectSelectFormField } from '../field/form/SelectFormField';
import { DefaultAccordion } from '../accordion/AccordionComponent';

const fetchData = async (dataProvider, startDate, endDate, endpoint, extraFilter = {}, sort = { field: 'createdAt', order: 'DESC' }, filters = true) => {
  let filter = { ...extraFilter };
  if (filters) {
    filter = { ...extraFilter, createdAt_ge: startDate, createdAt_le: endDate };
  }
  return dataProvider.getList(endpoint, {
    filter: filter,
    sort: sort,
    pagination: { page: 1, perPage: 25000 },
  });
};

const DefaultOverviewTab = ({
  fetchCompanySiteJobAnalyticsEndpoint,
  fetchCompanyStateAnalyticsEndpoint,
  fetchCompanySitesEnpoint,
  mockData,
}) => {
  const [companySiteJobAnalytics, setCompanySiteJobAnalytics] = useState([]);
  const [companyStateAnalytics, setCompanyStateAnalytics] = useState([]);
  const [extraMarkers, setExtraMarkers] = useState([]);

  const [dateRange, setDateRange] = useState({
    createdAtStart: moment().startOf('day').subtract(31, 'days'),
    createdAtEnd: moment().startOf('day').subtract(1, 'days'),
  });
  const [states, setStates] = useState('');
  const [country, setCountry] = useState('');

  const dataProvider = useDataProvider();

  const createdAtStartString = dateRange.createdAtStart.format('YYYY-MM-DD');
  const createdAtEndString = dateRange.createdAtEnd.format('YYYY-MM-DD');

  const fetchCompanySiteJobAnalytics = useCallback(async () => {
    let extraFilter = {};

    if (!_.isEmpty(country)) {
      extraFilter['companySite.site.address.country_eq'] = country;
    }
    if (!_.isEmpty(states)) {
      extraFilter['companySite.site.address.state_like'] = states;
    }
    const { data } = await fetchData(
      dataProvider,
      createdAtStartString,
      createdAtEndString,
      fetchCompanySiteJobAnalyticsEndpoint,
      extraFilter
    );
    setCompanySiteJobAnalytics(data);
  }, [
    country,
    states,
    dataProvider,
    createdAtStartString,
    createdAtEndString,
    fetchCompanySiteJobAnalyticsEndpoint,
  ]);

  const fetchCompanyStateAnalytics = useCallback(async () => {
    let extraFilter = {};
    if (!_.isEmpty(country)) {
      extraFilter['country_eq'] = country;
    }
    if (!_.isEmpty(states)) {
      extraFilter['state_like'] = states;
    }
    const { data } = await fetchData(
      dataProvider,
      createdAtStartString,
      createdAtEndString,
      fetchCompanyStateAnalyticsEndpoint,
      extraFilter
    );
    setCompanyStateAnalytics(data);
  }, [
    country,
    states,
    dataProvider,
    createdAtStartString,
    createdAtEndString,
    fetchCompanyStateAnalyticsEndpoint,
  ]);


  const fetchCompanySite = useCallback(async () => {
    let extraFilter = {};
    if (!_.isEmpty(country)) {
      extraFilter['site.address.country_eq'] = country;
    }
    if (!_.isEmpty(states)) {
      extraFilter['site.address.state_like'] = states;
    }
    const { data } = await fetchData(
      dataProvider,
      createdAtStartString,
      createdAtEndString,
      fetchCompanySitesEnpoint, extraFilter, { field: 'site.siteCode', order: 'ASC' }, false
    );
    const markers = [];
    for (let d of data) {
      markers.push({ id: d.id, name: d.site.siteName, loc: [d.site.geoLocation.latitude, d.site.geoLocation.longitude] });
    }
    setExtraMarkers(markers);
  }, [
    country,
    states,
    createdAtStartString,
    createdAtEndString,
    dataProvider,
    fetchCompanySitesEnpoint,
  ]);

  useEffect(() => {
    fetchCompanySiteJobAnalytics();
    fetchCompanyStateAnalytics();
  }, [createdAtStartString, createdAtEndString, country, states]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (fetchCompanySitesEnpoint) {
      fetchCompanySite();
    }
  }, [country, states]); // eslint-disable-line react-hooks/exhaustive-deps

  const lineChartData = createLineChartData(dateRange.createdAtStart, dateRange.createdAtEnd, {
    'Active Users': 0,
    'Active Sites': 0,
    Jobs: 0,
  });

  const dayCount = dateRange.createdAtEnd.diff(dateRange.createdAtStart, 'days');
  const { userData, userAvgData } = calculateUserCompanyAnalytics(
    companyStateAnalytics,
    lineChartData,
    dayCount,
    dateRange.createdAtEnd
  );

  const { companySiteData, companyAvgSiteData } = calculateCompanySiteAnalytics(
    companyStateAnalytics,
    lineChartData,
    dayCount,
    dateRange.createdAtEnd,
    extraMarkers ? extraMarkers.length : 0
  );

  const { companySiteJobData, companyAvgSiteJobData } = calculateCompanySiteJobAnalytics(
    companySiteJobAnalytics,
    lineChartData,
    dayCount,
    dateRange.createdAtEnd
  );

  const sortedLineChartData = sortObjectByKeys(lineChartData);
  const classes = useStyles();
  return (
    <Box>
      <Grid container>
        <Grid container justifyContent="flex-end">
          <DefaultAccordion expanded={false} label={'Filters (' + dayCount + ' Days)'}>
            <Grid container>
              <Grid item>
                <ObjectSelectFormField
                  selectedId={country}
                  valueChanged={(value) => {
                    setStates('');
                    setCountry(value);
                  }}
                  values={COUNTRIES}
                  label="Country"
                />
              </Grid>
              <Grid item>
                <ObjectSelectFormField
                  selectedId={states}
                  valueChanged={(value) => setStates(value)}
                  values={!_.isEmpty(country) ? STATES[country] : {}}
                  label="State"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <TextField
                  id="startDate"
                  label="Start date"
                  type="date"
                  defaultValue={dateRange.createdAtStart.format('YYYY-MM-DD')}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) =>
                    setDateRange({ ...dateRange, createdAtStart: moment(event.target.value) })
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  id="endDate"
                  label="End Date"
                  type="date"
                  defaultValue={dateRange.createdAtEnd.format('YYYY-MM-DD')}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: moment().startOf('day').subtract(1, 'days').format('YYYY-MM-DD'),
                  }}
                  onChange={(event) =>
                    setDateRange({ ...dateRange, createdAtEnd: moment(event.target.value) })
                  }
                />
              </Grid>
            </Grid>
          </DefaultAccordion>
        </Grid>
        <Grid container margin={1} justifyContent="center">
          <Grid item>
            <PieCard data={userData} title="Last Active Users" />
            <PieCard data={companySiteData} title="Last Active Sites" />
            <PieCard data={companySiteJobData} title="Last Jobs" />
          </Grid>
        </Grid>
        <Grid container margin={1} justifyContent="center">
          <Grid item>
            <PieCard data={userAvgData} title={'Average Active Users'} />
            <PieCard data={companyAvgSiteData} title={'Average Active Sites'} />
            <PieCard data={companyAvgSiteJobData} title={'Average Jobs'} />
          </Grid>
        </Grid>
        <Grid container margin={1} justifyContent="center">
          <Grid item xs sm md lg>
            <LineChartCard
              data={sortedLineChartData}
              title="Daily Statistics"
              lines={[
                { key: 'Active Users', color: '#8884d8' },
                { key: 'Active Sites', color: '#82ca9d' },
                { key: 'Jobs', color: '#00BFF2' },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container margin={1} justifyContent="center">
          <Grid item xs sm md lg>
            <MarkerMap
              title={'Jobs Map'}
              data={extractMapDatafromCompanySiteJobAnalytics(companySiteJobAnalytics)}
              extraMarkers={extraMarkers}
              mockData={mockData}></MarkerMap>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  textField: {
    alignSelf: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 200
  },
}));

export const OverviewTab = ({ managedCompany }) => (
  <DefaultOverviewTab
    fetchCompanySiteJobAnalyticsEndpoint="companySiteJobAnalytics"
    fetchCompanyStateAnalyticsEndpoint="companyStateAnalytics"
    fetchCompanySitesEnpoint='companySiteAnalytics'
    mockData={managedCompany && managedCompany.managedCompany.companyName === "CN" ? mockData : undefined}
  />
);
export const SharedOverviewTab = () => (
  <DefaultOverviewTab
    fetchCompanySiteJobAnalyticsEndpoint="sharedCompanySiteJobAnalytics"
    fetchCompanyStateAnalyticsEndpoint="sharedCompanyStateAnalytics"
    fetchCompanySitesEnpoint='sharedCompanySiteAnalytics'
  />
);

const mockData = [
  {
    id: 'Fort McMurray',
    name: 'Fort McMurray',
    loc: [56.6345, -111.124726],
  },
  {
    id: 'Jasper',
    name: 'Jasper',
    loc: [52.8736, -118.078692],
  },
  {
    id: 'Mirror',
    name: 'Mirror',
    loc: [52.4656, -113.120552],
  },
  {
    id: 'Vermilion',
    name: 'Vermilion',
    loc: [53.3521, -110.85286],
  },
  {
    id: 'Whitecourt',
    name: 'Whitecourt',
    loc: [54.1404, -115.684185],
  },
  {
    id: 'Chetwynd',
    name: 'Chetwynd',
    loc: [55.7018, -121.611445],
  },
  {
    id: 'Fort-James',
    name: 'Fort-James',
    loc: [54.4728, -124.189187],
  },
  {
    id: 'Mackenzie',
    name: 'Mackenzie',
    loc: [55.3052, -123.149801],
  },
  {
    id: 'Blue River',
    name: 'Blue River',
    loc: [52.1008, -119.303103],
  },
  {
    id: 'Nemoto',
    name: 'Nemoto',
    loc: [54.2387, -130.327522],
  },
  {
    id: 'Rivers',
    name: 'Rivers',
    loc: [50.0291, -100.238057],
  },
  {
    id: 'Armstrong',
    name: 'Armstrong',
    loc: [50.3009, -89.039714],
  },
  {
    id: 'Concord',
    name: 'Concord',
    loc: [43.799537, -79.505832],
  },
  {
    id: 'Foleyet',
    name: 'Foleyet',
    loc: [48.2451, -82.438575],
  },
  {
    id: 'Hornepayne',
    name: 'Hornepayne',
    loc: [49.2188, -84.775518],
  },
  {
    id: 'Chibougamou',
    name: 'Chibougamou',
    loc: [49.918728, -74.384933],
  },
  {
    id: 'Clova',
    name: 'Clova',
    loc: [48.1094, -75.360892],
  },
  {
    id: 'Lac Edouard',
    name: 'Lac Edouard',
    loc: [47.6586, -72.274499],
  },
  {
    id: 'Matagami',
    name: 'Matagami',
    loc: [49.7296, -77.706138],
  },
  {
    id: 'Parent',
    name: 'Parent',
    loc: [47.9207, -74.617549],
  },
  {
    id: 'Rouyn-Noranda',
    name: 'Rouyn-Noranda',
    loc: [48.244, -79.028062],
  },
  {
    id: 'Senneterre',
    name: 'Senneterre',
    loc: [48.3954, -77.254742],
  },
  {
    id: 'Biggar',
    name: 'Biggar',
    loc: [52.0546, -107.9879],
  },
  {
    id: 'Canora',
    name: 'Canora',
    loc: [51.6376, -102.436852],
  },
];