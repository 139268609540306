import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { FunctionField } from "react-admin";

const TimeField = ({ source }) => {
    return <FunctionField
        render={record => {
            const time = _.get(record, source, null);
            return time ? `${moment(time).format('HH:mm:ss')}` : ""
        }}
    />
};

export default TimeField;