import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        textAlign: 'center'
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
}));

const LineChartCard = (props: Props) => {
    const classes = useStyles();

    const { data = [], title = "", lines = [] } = props;
    return (
            <Card className={classes.root}>
                <CardHeader title={title} />
                <ResponsiveContainer width="95%" height={300}>
                <LineChart
                    data={data}
                    width={500}
                    height={300}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {lines.map(line => {
                        return (
                            <Line key={line.key} type="monotone" dataKey={line.key} stroke={line.color} />
                        );
                    })}
                </LineChart>
                </ResponsiveContainer>

            </Card>
    );
}

export default LineChartCard;