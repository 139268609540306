import { TranslationMessages } from 'ra-core';

export const en: TranslationMessages = {
  locspect: {
    resource: {
      menu: {
        company: "Company",
        managedCompany: "Managed Company",
        sharedCompany: "Shared Company",
        statistics: "Statistics",
        signOut: "Logout",
        back: "Back"
      },
      employees: {
        label: 'Employees',
        fields: {
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'Email',
          phone: 'Phone',
          role: 'Role',
          eRailSafe: 'eRailSafe',
        },
      },
      sites: {
        label: 'Sites',
        fields: {
          code: 'Code',
          siteName: 'Site Name',
          street: 'Street',
          city: 'City',
          state: 'State',
          country: 'Country',
          zip: 'Zip',
          latitude: 'Latitude',
          longitude: 'Longitude',
        },
      },
      workOrders: {
        label: "Work Order",
        fields: {
          number: "Number",
          companySite: "Site",
          company: "Company",
          representativeName: "Requestor",
          createdAt: "Created At",
          updatedAt: "Updated At",
          status: "Work Order Status",
          type: "Type of Work Order",
          poWoNumber: "PO/WO Number",
          costCenter: "Cost Centre",
          requestedStartDate: "Date of Request",
          requestedStartDateAfter: "After Date of Request",
          requestedStartDateBefore: "Before Date of Request",
          assignedTo: "Assigned to",
          startDate: "Start Date",
          endDate: "Date of Completion",
          createdBy: "Creator",
          updatedBy: "Updator",
          beforePicture: "Before Picture",
          afterPicture: "After Picture",
          firstName: "First Name",
          lastName: "Last Name",
          rate: "Hourly Rate",
          totalCost: "Total Cost",
          employee: "Employee",
          materialName: "Name",
          materialPrice: "Price",
          materialQuantity: "Quantity",
          materialTotal: "Total Price",
          description: "Description",
          siteName: 'Site Name',
          street: 'Street',
          city: 'City',
          state: 'State',
          country: 'Country',
          zip: 'Zip',
          statusInput: {
            OPEN: "Open",
            CLOSED: "Closed"
          },
          typeInput: {
            NEW_REQUEST: "New Request",
            CHANGE: "Change",
            PERIODICAL: "Periodical",
            COMPLAINT_ACTION_REQUIRED: "Complaint/Action Required"
          },
          history: "History",
          print: "Print"
        }
      },
      assessments: {
        label: 'Assessment',
        survey: 'Survey',
        inspection: 'Inspection',
        fields: {
          assessmentType: 'Assessment Type',
          assessment: 'Name',
          questions: 'Questions',
          question: 'Question',
          required: 'Required',
          allowAllCompSites: 'Allow for all managed sites',
          extraDetails: 'Allow for extra comments and images',
          answerType: {
            label: 'Answer Type',
            RADIO_SELECT_5: '1 to 5 Rating',
            RADIO_SELECT_YES_NO: 'Yes or No',
            CHECK_BOX: 'Checkbox',
            SINGLE_LINE_TEXT: 'Single line text',
            MULTI_LINE_TEXT:'Multiple line text',
          },
        },
      },
      assessmentSites: {
        label: 'sites',
        fields: {
          assessment: 'Name',
          companyName: 'Company',
          code: 'Code',
          siteName: 'Site Name',
          street: 'Street',
          city: 'City',
          state: 'State',
          country: 'Country',
          zip: 'Zip',
          managedSite: 'Managed Site'
        },
      },
      assessmentResults: {
        label: 'Results',
        fields: {
          assessment: 'Name',
          companyName: 'Company',
          code: 'Code',
          siteName: 'Site Name',
          createdAt: 'Created At',
          assessmentQuestion: 'Question',
          answer: 'Answer',
        },
      },
      assessmentUsercompanyResults: {
        label: 'Assessment Results',
        fields: {
          assessment: 'Name',
          firstName: 'First Name',
          lastName: 'Last Name',
          createdAt: 'Created At',
          assessmentQuestion: 'Question',
          answer: 'Answer',
        },
      },
      sharedSites: {
        label: 'Sites',
        fields: {
          code: 'Code',
          siteName: 'Site Name',
          companyName: 'Company Name',
          cleanUpDate: 'Last Clean Up Date',
          cleanUpDateStart: 'Clean Up Date Start',
          cleanUpDateEnd: 'Clean Up Date End',
          state: 'State',
          country: 'Country',
          role: 'Role',
        },
      },
      sharedSiteJobs: {
        label: 'Jobs',
        fields: {
          code: 'Code',
          siteName: 'Site Name',
          companyName: 'Company Name',
          startDate: 'Start Date',
          startTime: 'Start Time',
          startTimeStart: 'Start Time Start',
          startTimeEnd: 'Start Time End',
          endDate: 'End Date',
          endTime: 'End Time',
          endTimeStart: 'End Time Start',
          endTimeEnd: 'End Time End',
          period: 'Period',
          duration: 'Duration',
          state: 'State',
          country: 'Country',
          role: 'Role',
        },
      },
      managedSites: {
        label: 'Sites',
        fields: {
          code: 'Code',
          siteName: 'Site Name',
          companyName: 'Company Name',
          cleanUpDate: 'Last Clean Up Date',
          cleanUpDateStart: 'Clean Up Date Start',
          cleanUpDateEnd: 'Clean Up Date End',
          street: 'Street',
          city: 'City',
          state: 'State',
          country: 'Country',
          zip: 'Zip',
          role: 'Role',
          managedCompany: 'Managed Company',
          jobCount: 'Job Count',
          frequency: 'Frequency',
          frequencyType: 'Frequency Type',
        },
      },
      managedSiteJobs: {
        label: 'Jobs',
        fields: {
          code: 'Code',
          siteName: 'Site Name',
          companyName: 'Company Name',
          startDate: 'Start Date',
          startTime: 'Start Time',
          startTimeStart: 'Start Time Start',
          startTimeEnd: 'Start Time End',
          endDate: 'End Date',
          endTime: 'End Time',
          endTimeStart: 'End Time Start',
          endTimeEnd: 'End Time End',
          duration: 'Duration (H)',
          period: 'Period',
          distance: 'Employee Distance (KM)',
          employeeName: 'Employee Name',
          firstName: 'First Name',
          lastName: 'Last Name',
          state: 'State',
          country: 'Country',
          role: 'Role',
          eRailSafe: 'eRailSafe',
        },
      },
      managedSiteAlerts: {
        label: 'Alerts',
        fields: {
          code: 'Code',
          siteName: 'Site Name',
          companyName: 'Company Name',
          status: 'Status',
          alertDate: 'Date',
          alertDateStart: 'Date Start',
          alertDateEnd: 'Date End',
          state: 'State',
          country: 'Country',
          role: 'Role',
        },
      },
    },
  },
};
