import React from 'react';
import Button from '@mui/material/Button';
import { useRecordContext } from 'react-admin';
import { ButtonGroup } from '@mui/material';
const QRCode = require('qrcode');

const drawCirlWithCross = (ctx, x, y, r) => {
  ctx.beginPath();
  ctx.arc(x, y, r, 0, Math.PI * 2, true);
  ctx.lineWidth = 4;
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(x, y - r);
  ctx.lineTo(x, y + r);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(x - r, y);
  ctx.lineTo(x + r, y);
  ctx.stroke();
};

const handleClick = async (record, version = 1) => {
  const code = record.siteCode ? record.siteCode : record.site.siteCode;
  const name = record.siteName ? record.siteName : record.site.siteName;
  const companyShortCode =
    record.company && record.company.companyShortCode
      ? record.company.companyShortCode
      : record.site.company.companyShortCode;

  const width = 1024;
  var qrCanvas = document.createElement('canvas');
  await QRCode.toCanvas(
    qrCanvas,
    version === 2 ? `https://app.locspect.com/mapp/scans?code=${encodeURIComponent(code)}&name=${encodeURIComponent(name)}&companyShortCode=${encodeURIComponent(companyShortCode)}` : JSON.stringify({ code: code, name: name, companyShortCode: companyShortCode }) ,
    { width: width, errorCorrectionLevel: 'Q' }
  );

  var ctx = qrCanvas.getContext('2d');
  ctx.font = '40px bold';
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center';
  ctx.fillText(code.toUpperCase(), width / 2, 50);

  var textWidth = ctx.measureText(name.toUpperCase()).width;
  if (textWidth > 1024) {
    ctx.font = '20px bold';
  } else if (textWidth > 800) {
    ctx.font = '26px bold';
  } else {
    ctx.font = '30px bold';
  }
  ctx.fillText(name.toUpperCase(), width / 2, width - 45);

  ctx.beginPath();
  ctx.rect(10, 10, width - 20, width - 20);
  ctx.lineWidth = 7;
  ctx.strokeStyle = 'black';
  ctx.stroke();

  const radius = 20;
  const startX = 45;
  const startY = 45;
  drawCirlWithCross(ctx, startX, startY, radius);
  drawCirlWithCross(ctx, width - startX, startY, radius);
  drawCirlWithCross(ctx, startX, width - startY, radius);
  drawCirlWithCross(ctx, width - startX, width - startY, radius);

  var link = document.createElement('a');
  link.download = `${code}_v${version}.png`;
  link.href = qrCanvas.toDataURL();
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const QRCodeButton = () => {
  const record = useRecordContext();
  return (
    <ButtonGroup
      variant="outlined"
      aria-label="QR button group"
    >
      <Button onClick={() => handleClick(record)}>
        QR
      </Button>
      <Button onClick={() => handleClick(record, 2)}>
        QR V2
      </Button>
    </ButtonGroup>
  );
};

export default QRCodeButton;
