import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { LOCSPECT_API_URL } from "../config/config";

const SurveyApi = {
    getSurvey: async (surveySiteId) => {
        let assessmentResult = undefined;
        try {
            assessmentResult = await fetch(`${LOCSPECT_API_URL}/surveys/sites/${surveySiteId}`, {
                headers: {
                    Accept: 'application/json',
                    'trackingNumber': uuidv4()
                }
            });

            if (assessmentResult && assessmentResult.ok) {
                const assessment = await assessmentResult.json();
                if (!_.isEmpty(assessment)) {
                    return assessment;
                }
                return Promise.reject({ status: assessmentResult.status, body: "No assessment found" });
            }
        } catch (error) {
        }

        return Promise.reject({ status: assessmentResult ? assessmentResult.status : 503, body: assessmentResult ? assessmentResult.json().catch(e => "Something is wrong?") : "Something is wrong" });
    },
    addSurveyAnswers: async (surveySiteId, questionAnswers) => {
        const managedCompaniesResult = await fetch(`${LOCSPECT_API_URL}/surveys/sites/${surveySiteId}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'trackingNumber': uuidv4(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(questionAnswers)
        });

        if (managedCompaniesResult.status === 201) {
            return Promise.resolve();
        }
        return Promise.reject({ status: managedCompaniesResult.status, body: managedCompaniesResult.json().catch(e => "Something is wrong?") });

    }
};

export default SurveyApi;