import _ from "lodash";
import * as React from "react";
import { FunctionField } from "react-admin";

const NameField = ({ source, source2 }) => {
    return <FunctionField
        render={record => {
            const firstName = _.get(record, source, "");
            const lastName = _.get(record, source2, "");
            return `${firstName} ${lastName}`
        }
        }
    />
};

export default NameField;