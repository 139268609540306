// in src/MyAppBar.js
import * as React from 'react';
import { AppBar, UserMenu, useTranslate } from 'react-admin';
import Grid from '@mui/material/Grid';
import MyLogoutButton from '../button/MyLogoutButton';
import { ArrayOfObjectSelectFormField } from '../field/form/SelectFormField';

const MyUserMenu = () => (
  <UserMenu>
    <MyLogoutButton />
  </UserMenu>
);

const MyAppBar = (props) => {
  const {
    managedcompanies,
    setmanagedcompany,
    managedcompany,
    sharedcompanies,
    setsharedcompany,
    sharedcompany,
    userCompanies,
    setUserCompany,
    userCompany,
  } = props;
  const translate = useTranslate();

  return (
    <AppBar userMenu={<MyUserMenu />} position="sticky" style={{ paddingTop: 2 }}>
      <Grid container>
        <ArrayOfObjectSelectFormField
          values={userCompanies}
          selectedId={userCompany.id}
          valueChanged={setUserCompany}
          label={translate("locspect.resource.menu.company")}
          valueKey="company.companyName"
          emptyValue={false}
        />
        {window.location.hash.includes('/shared') && sharedcompanies && (
          <ArrayOfObjectSelectFormField
            values={sharedcompanies}
            selectedId={sharedcompany ? sharedcompany.id : 'NONE'}
            valueChanged={setsharedcompany}
            label={translate("locspect.resource.menu.sharedCompany")}
            valueKey="sharedCompany.companyName"
          />
        )}
        {window.location.hash.includes('/managed') && managedcompanies && (
          <ArrayOfObjectSelectFormField
            values={managedcompanies}
            selectedId={managedcompany ? managedcompany.id : 'NONE'}
            valueChanged={setmanagedcompany}
            label={translate("locspect.resource.menu.managedCompany")}
            valueKey="managedCompany.companyName"
          />
        )}
      </Grid>
    </AppBar>
  );
};

export default MyAppBar;
