/* eslint-disable react-hooks/exhaustive-deps */
import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { Admin, CustomRoutes, fetchUtils, Resource, withLifecycleCallbacks } from 'react-admin';
import AuthProvider from '../api/AuthProvider';
import CompanyApi from '../api/CompanyApi';
import JsonDataProvider, { ENDPOINTS } from '../api/JsonDataProvider';
import { LOCSPECT_API_URL, keycloak as kc } from '../config/config';
import { ManagedCompanyAlertList } from '../list/managed/ManagedCompanyAlertList';
import { ManagedCompanyJobEdit, ManagedCompanyJobList } from '../list/managed/ManagedCompanyJobList';
import { ManagedCompanySiteCreate, ManagedCompanySiteEdit, ManagedCompanySiteList } from '../list/managed/ManagedCompanySiteList';
import { CompanySharedSiteList } from '../list/shared/CompanySharedSiteList';
import { CompanyUserEdit, CompanyUserList } from '../list/company/CompanyUserList';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import en from 'ra-language-english';
import fr from 'ra-language-french';
import * as englishMessages from '../config/i18n/englishMessages';
import * as frenchMessages from '../config/i18n/frenchMessages';
import { CompanySharedJobList } from '../list/shared/CompanySharedJobList';
import { CompanySiteCreate, CompanySiteEdit, CompanySiteList } from '../list/company/CompanySiteList';
import { v4 as uuidv4 } from 'uuid';
import MyLayout from '../layout/MyLayout';
import _ from 'lodash';
import { stringify } from 'query-string';
import { Route } from 'react-router-dom';
import { SharedOverviewTab } from '../tab/OverviewTab';
import { StatisticsTab } from '../tab/OverviewStatisticsTabs';
import { AssessmentCreate, AssessmentList } from '../list/assessment/AssessmentList';
import { AssessmentResultList } from '../list/assessment/AssessmentResultList';
import { AssessmentSiteCreate, AssessmentSiteList } from '../list/assessment/AssessmentSiteList';
import { WorkOrderCreate, WorkOrderEdit, WorkOrderList } from '../list/workorder/WorkOrderList';
import { convertImages } from '../utils/ImageUtils';
import { AssessmentUserCompanyResultList } from '../list/assessment/AssessmentUserCompanyResultList';


const translations = {
  en: { ...en, ...englishMessages.en },
  fr: { ...fr, ...frenchMessages.fr },
};

export const i18nProvider = polyglotI18nProvider(
  locale => translations[locale],
  'en',
  [{ locale: 'en', name: 'English' }, { locale: 'fr', name: 'Français' }],
);

const MainView = () => {
  const { keycloak, initialized } = useKeycloak();
  const [userCompanyData, setUserCompanyData] = React.useState(null);
  const [companyData, setCompanyData] = React.useState(null);
  const [err, setErr] = React.useState(false);

  const fetchJson = (url, options = {}) => {
    let finalUrl = url;
    if (url.includes('{companyId}')) {
      finalUrl = url.replace('{companyId}', userCompanyData.userCompany.company.id);
    }

    if (!_.isEmpty(options)) {
      let query = options.query || {};
      if (options.filters) {
        for (const [key, value] of Object.entries(options.filters)) {
          if (value === '{managedCompanyId}') {
            if (!_.isEmpty(companyData.managedCompany)) {
              options.filters[key] = companyData.managedCompany.managedCompany.id;
            } else {
              delete options.filters[key];
            }
          } else if (value === '{sharedCompanyId}') {
            if (!_.isEmpty(companyData.sharedCompany)) {
              options.filters[key] = companyData.sharedCompany.sharedCompany.id;
            } else {
              delete options.filters[key];
            }
          }
        }

        query['filters'] = JSON.stringify(options.filters);
        if (options.perPage === 1000) {
          query['size'] = 25000;
        }
      }

      if (options.configs) {
        query = {
          ...options.configs,
          ...query,
        };
      }
      if(!_.isEmpty(query)) {
        finalUrl = `${finalUrl}?${stringify(query)}`;
      }
    }

    if (!options.headers) {
      options.headers = new Headers();
    }

    options.headers.set('Authorization', 'Bearer ' + kc.token);
    options.headers.set('Accept', 'application/json');
    options.headers.set('trackingNumber', uuidv4());

    return fetchUtils.fetchJson(finalUrl, options);
  };

  const workOrderImageConverter = async (params, dataProvider) => {
    return {
      ...params,
      data: {
        ...params.data,
        beforeDocuments: await convertImages(params.data.beforeDocuments, "BEFORE"),
        afterDocuments: await convertImages(params.data.afterDocuments, "AFTER")
      }
    }
  }

  const dataProvider = withLifecycleCallbacks(JsonDataProvider(LOCSPECT_API_URL, fetchJson), [
    {
      /**
       * For posts update only, convert uploaded images to base 64 and attach them to
       * the `picture` sent property, with `src` and `title` attributes.
       */
      resource: 'workOrders',
      beforeUpdate: workOrderImageConverter,
      beforeCreate: workOrderImageConverter,
      afterRead: async (data, dataProvider) => {
        if (data) {
          // rename field to the record
          if (data.beforeDocuments) {
            data.beforeDocuments.map(doc => {
              doc.src = doc.path;
              doc.title = doc.name;
              return doc;
            });
          }
          if (data.afterDocuments) {
            data.afterDocuments.map(doc => {
              doc.src = doc.path;
              doc.title = doc.name;
              return doc;
            });
          }
        }
        return data;
      },

    }
  ]);

  const updateManagedCompany = (mc) => {
    setCompanyData({
      ...companyData,
      managedCompany: mc,
    });
  };

  const updateSharedCompany = (sc) => {
    setCompanyData({
      ...companyData,
      sharedCompany: sc,
    });
  };

  const updateUserCompany = (uc) => {
    if (uc == null || uc.id !== userCompanyData.userCompany.id) {
      setUserCompanyData({
        ...userCompanyData,
        userCompany: uc,
      });
    }
  };

  React.useEffect(() => {
    if (initialized && keycloak.authenticated && _.isEmpty(companyData)) {
      const getCompanyInfo = async () => {
        let uc = null;
        try {
          uc = await CompanyApi.getCompanies(keycloak.token);
        } catch (e) {
          setErr(e);
        }
        if (!_.isEmpty(uc)) {
          const userCompany = uc[0];

          setUserCompanyData({
            userCompany: userCompany,
            userCompanies: uc,
          });

          setCompanyData({
            managedCompanies: !_.isEmpty(userCompany.company.managedCompanies) ? userCompany.company.managedCompanies : null,
            managedCompany: !_.isEmpty(userCompany.company.managedCompanies) ? userCompany.company.managedCompanies[0] : null,
            sharedCompanies: !_.isEmpty(userCompany.company.sharedCompanies) ? userCompany.company.sharedCompanies : null,
            sharedCompany: !_.isEmpty(userCompany.company.sharedCompanies) ? userCompany.company.sharedCompanies[0] : null,
          });
        } else {
          setErr({ status: 404, body: 'No company found.' });
        }
      };
      getCompanyInfo();
    }
  }, [keycloak, initialized]);

  if (initialized && keycloak.authenticated && companyData) {
    const userCompany = userCompanyData.userCompany;
    const role = userCompany.userCompanyRole.role;

    return (
      <Admin
        layout={(props) => (
          <MyLayout
            {...props}
            managedcompanies={companyData.managedCompanies}
            setmanagedcompany={updateManagedCompany}
            managedcompany={companyData.managedCompany}
            sharedcompanies={companyData.sharedCompanies}
            setsharedcompany={updateSharedCompany}
            sharedcompany={companyData.sharedCompany}
            userCompanies={userCompanyData.userCompanies}
            setUserCompany={updateUserCompany}
            userCompany={userCompany}
            role={role}></MyLayout>
        )}
        i18nProvider={i18nProvider}
        loginPage={false}
        dataProvider={dataProvider}
        authProvider={AuthProvider}>
        {() => {
          const resources = [];
          if (role === 'ADMIN') {
            resources.push(
              <Resource
                options={{ key: 'employees', label: 'locspect.resource.employees.label' }}
                name={ENDPOINTS.users.name}
                recordRepresentation={(record) => `${record.user.firstName} ${record.user.lastName}`}
                list={CompanyUserList}
                edit={CompanyUserEdit}
              />
            );
            resources.push(
              <Resource
                options={{
                  key: 'sites',
                  label: 'locspect.resource.sites.label',
                  company:
                    userCompanyData &&
                      userCompanyData.userCompany &&
                      userCompanyData.userCompany.company
                      ? userCompanyData.userCompany.company
                      : null,
                }}
                name={ENDPOINTS.sites.name}
                list={CompanySiteList}
                edit={CompanySiteEdit}
                create={CompanySiteCreate}
              />
            );
            resources.push(
              <Resource
                options={{
                  key: 'acknowledgements',
                  label: 'locspect.resource.assessmentUsercompanyResults.label',
                  company:
                    userCompanyData &&
                      userCompanyData.userCompany &&
                      userCompanyData.userCompany.company
                      ? userCompanyData.userCompany.company
                      : null,
                }}
                name={ENDPOINTS['acknowledgements/results'].name}
                list={AssessmentUserCompanyResultList}
              />
            );


            resources.push(
              <Resource
                options={{
                  key: 'surveys',
                  label: 'locspect.resource.assessments.survey',
                  company:
                    userCompanyData &&
                      userCompanyData.userCompany &&
                      userCompanyData.userCompany.company
                      ? userCompanyData.userCompany.company
                      : null,
                }}
                create={() => <AssessmentCreate assessmentType="SURVEY" />}
                name={ENDPOINTS.surveys.name}
                list={AssessmentList}
                recordRepresentation="name"
              />
            );



            resources.push(
              <Resource
                options={{
                  key: 'surveysSites',
                  label: 'locspect.resource.assessmentSites.label',
                  company:
                    userCompanyData &&
                      userCompanyData.userCompany &&
                      userCompanyData.userCompany.company
                      ? userCompanyData.userCompany.company
                      : null,

                }}
                create={() => <AssessmentSiteCreate assessmentReference={ENDPOINTS.surveys.name} assessmentLabel={"locspect.resource.assessments.survey"} />}
                name={ENDPOINTS["surveys/sites"].name}
                list={AssessmentSiteList}
              />
            );


            resources.push(
              <Resource
                options={{
                  key: 'surveysResults',
                  label: 'locspect.resource.assessmentResults.label',
                  company:
                    userCompanyData &&
                      userCompanyData.userCompany &&
                      userCompanyData.userCompany.company
                      ? userCompanyData.userCompany.company
                      : null,
                }}
                name={ENDPOINTS['surveys/results'].name}
                list={AssessmentResultList}
              />
            );



            resources.push(
              <Resource
                options={{
                  key: 'inspections',
                  label: 'locspect.resource.assessments.inspection',
                  company:
                    userCompanyData &&
                      userCompanyData.userCompany &&
                      userCompanyData.userCompany.company
                      ? userCompanyData.userCompany.company
                      : null,
                }}
                create={() => <AssessmentCreate assessmentType="INSPECTION" />}
                name={ENDPOINTS.inspections.name}
                list={AssessmentList}
                recordRepresentation="name"
              />
            );
            resources.push(
              <Resource
                options={{
                  key: 'inspectionsSites',
                  label: 'locspect.resource.assessmentSites.label',
                  company:
                    userCompanyData &&
                      userCompanyData.userCompany &&
                      userCompanyData.userCompany.company
                      ? userCompanyData.userCompany.company
                      : null,

                }}
                create={<AssessmentSiteCreate assessmentReference={ENDPOINTS.inspections.name} assessmentLabel={"locspect.resource.assessments.inspection"} />}
                name={ENDPOINTS["inspections/sites"].name}
                list={AssessmentSiteList}
              />
            );


            resources.push(
              <Resource
                options={{
                  key: 'inspectionsResults',
                  label: 'locspect.resource.assessmentResults.label',
                  company:
                    userCompanyData &&
                      userCompanyData.userCompany &&
                      userCompanyData.userCompany.company
                      ? userCompanyData.userCompany.company
                      : null,
                }}
                name={ENDPOINTS['inspections/results'].name}
                list={AssessmentResultList}
              />
            );

            if (!_.isEmpty(companyData.sharedCompanies)) {
              resources.push(
                <CustomRoutes>
                  <Route path="/shared/statistics" element={<SharedOverviewTab />} />
                </CustomRoutes>
              );
              resources.push(
                <Resource
                  options={{ key: 'sharedSites', label: 'locspect.resource.sharedSites.label' }}
                  name={ENDPOINTS['shared/sites'].name}
                  list={CompanySharedSiteList}
                />
              );
              resources.push(
                <Resource
                  options={{
                    key: 'sharedSiteJobs',
                    label: 'locspect.resource.sharedSiteJobs.label',
                  }}
                  name={ENDPOINTS['shared/site/jobs'].name}
                  list={CompanySharedJobList}
                />
              );
            }

            if (!_.isEmpty(companyData.managedCompanies)) {
              resources.push(
                <CustomRoutes>
                  <Route path="/managed/company/statistics" element={<StatisticsTab managedCompany={companyData.managedCompany} />} />
                </CustomRoutes>
              );
              resources.push(
                <Resource
                  options={{ key: 'managedSites', label: 'locspect.resource.managedSites.label', role: role }}
                  name={ENDPOINTS['managed/company/sites'].name}
                  list={ManagedCompanySiteList}
                  recordRepresentation="site.siteName"
                  create={(props) => (
                    <ManagedCompanySiteCreate
                      {...props}
                      managedCompanies={companyData.managedCompanies}></ManagedCompanySiteCreate>
                  )}
                  edit={ManagedCompanySiteEdit}
                />
              );
              resources.push(
                <Resource
                  options={{
                    key: 'managedSiteJobs',
                    role: role,
                    label: 'locspect.resource.managedSiteJobs.label',
                  }}
                  name={ENDPOINTS['managed/company/site/jobs'].name}
                  list={ManagedCompanyJobList}
                  edit={ManagedCompanyJobEdit}
                />
              );
              resources.push(
                <Resource
                  options={{
                    key: 'managedSiteAlerts',
                    label: 'locspect.resource.managedSiteAlerts.label',
                  }}
                  name={ENDPOINTS['managed/company/site/alerts'].name}
                  list={ManagedCompanyAlertList}
                />
              );


              resources.push(
                <Resource
                  options={{
                    key: 'workOrders',
                    label: 'locspect.resource.workOrders.label',
                    company:
                      userCompanyData &&
                        userCompanyData.userCompany &&
                        userCompanyData.userCompany.company
                        ? userCompanyData.userCompany.company
                        : null,

                  }}
                  edit={() => <WorkOrderEdit managedcompanies={companyData.managedCompanies} role={role} />}
                  create={() => <WorkOrderCreate managedcompanies={companyData.managedCompanies} role={role}  />}
                  name={ENDPOINTS.workOrders.name}
                  list={() => <WorkOrderList role={role} />}
                />

              )

            }
          } else if (role === 'SUPERVISOR') {
            if (!_.isEmpty(companyData.managedCompany)) {
              resources.push(
                <Resource
                  options={{ key: 'managedSites', label: 'locspect.resource.managedSites.label', role: role }}
                  name={ENDPOINTS['managed/company/sites'].name}
                  list={ManagedCompanySiteList}
                  recordRepresentation="site.siteName"
                />
              );
              resources.push(
                <Resource
                  options={{
                    key: 'managedSiteJobs',
                    role: role,
                    label: 'locspect.resource.managedSiteJobs.label',
                  }}
                  name={ENDPOINTS['managed/company/site/jobs'].name}
                  list={ManagedCompanyJobList}
                />
              );
              resources.push(
                <Resource
                  options={{
                    key: 'workOrders',
                    label: 'locspect.resource.workOrders.label',
                    company:
                      userCompanyData &&
                        userCompanyData.userCompany &&
                        userCompanyData.userCompany.company
                        ? userCompanyData.userCompany.company
                        : null,

                  }}
                  edit={() => <WorkOrderEdit managedcompanies={companyData.managedCompanies} />}
                  create={() => <WorkOrderCreate managedcompanies={companyData.managedCompanies} />}
                  name={ENDPOINTS.workOrders.name}
                  list={() => <WorkOrderList role={role} />}
                />

              )
            }
          }
          return resources;
        }}
      </Admin>
    );
  }
  return (
    <>
      {err && (
        <div>
          <h1 style={{ textAlign: 'center' }}>
            Something went wrong!
            <p>{err.status}</p>
            <p>{err.body}</p>
          </h1>
        </div>
      )}
    </>
  );
};

export default MainView;
