import * as React from "react";
import { AsYouType } from 'libphonenumber-js'
import { useRecordContext } from "react-admin";

const PhoneField = ({ source }) => {
    const record = useRecordContext();
    const number = source.split('.').reduce((o, i) => o[i], record);
    return (<a href={"tel:" + number}>
        {new AsYouType('US').input(number)}
    </a>);
};


export default PhoneField;