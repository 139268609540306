// in src/MyLogoutButton.js
import * as React from 'react';
import { forwardRef } from 'react';
import { useLogout, useTranslate } from 'react-admin';
import MenuItem from '@mui/material/MenuItem';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import { useKeycloak } from '@react-keycloak/web';

const MyLogoutButton = forwardRef((_, ref) => {
    const translate = useTranslate();
    const logout = useLogout();
    const { keycloak } = useKeycloak();
    const handleClick = async () => {
        await keycloak.logout();
        logout();
    }
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
        >
            <ExitIcon /> {translate('locspect.resource.menu.signOut')}
        </MenuItem>
    );
});

export default MyLogoutButton;