import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './css/index.css';
import App from './App';
import { REACT_APP_NAME, REACT_APP_VERSION, SENTRY_DNS, SENTRY_ENVIRONMENT } from './config/config';

Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
    environment: SENTRY_ENVIRONMENT,
    release: REACT_APP_NAME + "@" + REACT_APP_VERSION,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
export default Sentry.withProfiler(App);