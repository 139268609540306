import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { FunctionField } from "react-admin";

const DateField = ({ source }) => {
    return <FunctionField
        render={record => {
            const date = _.get(record, source, null);
            return date ? `${moment(date).format('YYYY-MM-DD')}` : ""
        }}
    />
};


export default DateField;