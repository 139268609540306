import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import {
  List,
  Datagrid,
  TextField,
  SimpleList,
  downloadCSV,
  SimpleShowLayout,
  ArrayField,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { DefaultPagination } from '../../pagination/Pagination';
import DateTimeField from '../../field/DateTimeField';

const exporter = (assessments) => {
  const assessmentForExport = assessments.map((assessment) => {
    let assessmentForExport = {};
    assessmentForExport.name = assessment.assessmentSite.assessment.name;
    assessmentForExport.company_name = assessment.assessmentSite.companySite.site.company.companyName;
    assessmentForExport.site_code = assessment.assessmentSite.companySite.site.siteCode;
    assessmentForExport.site_name = assessment.assessmentSite.companySite.site.siteName;
    assessmentForExport.created_at = assessment.createdAt;
    assessmentForExport.results = assessment.assessmentReportAnswers.map(function (item) {
      return item['assessmentQuestion']['questionCode'] + ":" + item['answer'];
    });
    assessmentForExport.street = assessment.assessmentSite.companySite.site.address.street;
    assessmentForExport.city = assessment.assessmentSite.companySite.site.address.city;
    assessmentForExport.state = assessment.assessmentSite.companySite.site.address.state;
    assessmentForExport.country = assessment.assessmentSite.companySite.site.address.country;
    assessmentForExport.zip = assessment.assessmentSite.companySite.site.address.zip;

    return assessmentForExport;
  });
  jsonExport(
    assessmentForExport,
    {
      headers: ['name', 'company_name', 'site_code', 'site_name', 'created_at', 'results', 'street', 'city', 'country', 'zip'],
    },
    (err, csv) => {
      downloadCSV(csv, 'assessment_result_list');
    }
  );
};


const ResultShow = () => {
  return (
    <SimpleShowLayout>
      <ArrayField label="" source="assessmentReportAnswers">
        <Datagrid bulkActionButtons={false}>
          <TextField label="locspect.resource.assessmentResults.fields.assessmentQuestion" source="assessmentQuestion.value" />
          <TextField label="locspect.resource.assessmentResults.fields.answer" source="answer" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  )
};

export const AssessmentResultList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <List perPage={25} pagination={<DefaultPagination />} exporter={exporter}>
      {isSmall ? (
        <SimpleList primaryText={(record) => `${record.assessmentSite.assessment.name}`} />
      ) : (
        <Datagrid bulkActionButtons={false} expand={ResultShow}>
          <TextField label="locspect.resource.assessmentResults.fields.assessment" source="assessmentSite.assessment.name" />
          <TextField label="locspect.resource.assessmentResults.fields.companyName" source="assessmentSite.companySite.site.company.companyName" />
          <TextField label="locspect.resource.assessmentResults.fields.code" source="assessmentSite.companySite.site.siteCode" />
          <TextField label="locspect.resource.assessmentResults.fields.siteName" source="assessmentSite.companySite.site.siteName" />
          <DateTimeField
            label="locspect.resource.assessmentResults.fields.createdAt"
            source="createdAt"
          />
        </Datagrid>
      )}
    </List>
  );
};
