import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Box, Button, Card, CardContent, Checkbox, CircularProgress, Divider, FormHelperText, TextField, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import SurveyApi from '../api/SurveyApi';
import _ from 'lodash';
import logo from '../assets/logo192.png';

export default function SurveyForm() {
    const [surveyData, setSurveyData] = React.useState(undefined);
    const [surveyAnswers, setSurveyAnswers] = React.useState(undefined);
    const [surveyErrors, setSurveyErrors] = React.useState({});
    const [done, setDone] = React.useState(false);
    const [error, setError] = React.useState(undefined);

    const getDefaultValue = (answerType) => {
        if (answerType === "CHECK_BOX" || answerType === "RADIO_SELECT_YES_NO") {
            return false;
        }
        return "";
    }


    React.useEffect(() => {
        if (!done && !surveyData) {
            const getSurvey = async (surveySiteId) => {
                try {
                    const data = await SurveyApi.getSurvey(surveySiteId);
                    if (data && data.assessmentQuestions) {
                        let questionAnswers = {}
                        for (let question of data.assessmentQuestions) {
                            questionAnswers[question.id] = {
                                value: getDefaultValue(question.answerType),
                                required: question.required
                            };
                        }
                        setSurveyAnswers(questionAnswers);
                        setSurveyData(data);
                    }
                } catch (error) {
                    setError(error?.status === 404 ? "NOT_FOUND" : "ERROR");
                }
            }

            const searchParams = new URLSearchParams(window.location.search);
            const surveySiteId = searchParams.get('surveySiteId');
            if (surveySiteId) {
                getSurvey(surveySiteId);
            }
        }
    }, [surveyData, done]);

    const onComponentChange = (event, assessmentQuestionId) => setSurveyAnswers({
        ...surveyAnswers,
        [assessmentQuestionId]: {
            ...surveyAnswers[assessmentQuestionId],
            value: event.target.value,

        }
    });

    const onComponentChecked = (event, assessmentQuestionId) => setSurveyAnswers({
        ...surveyAnswers,
        [assessmentQuestionId]: {
            ...surveyAnswers[assessmentQuestionId],
            value: event.target.checked,

        }
    });

    const getComponent = (question) => {
        const answerType = question.answerType;
        if (answerType === "CHECK_BOX") {
            return (<Box flex={1}>
                <FormLabel sx={{ color: "black", fontWeight: 'bold', fontSize: 'h6.fontSize' }} required={question.required}>{question.value}</FormLabel>
                <Checkbox style={{ alignSelf: 'flex-end' }} checked={surveyAnswers[question.id].value} onChange={(event) => onComponentChecked(event, question.id)} />
                <FormHelperText>{surveyErrors[question.id]}</FormHelperText>
            </Box>);
        }
        return (<>
            <FormLabel sx={{ color: "black", fontWeight: 'bold', fontSize: 'h6.fontSize' }} required={question.required}>{question.value}</FormLabel>
            {answerType === "RADIO_SELECT_5" && <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={surveyAnswers[question.id].value}
                onChange={(event) => onComponentChange(event, question.id)}
            >
                <FormControlLabel value="5" control={<Radio />} label="Excellent" />
                <FormControlLabel value="4" control={<Radio />} label="Very Good" />
                <FormControlLabel value="3" control={<Radio />} label="Good" />
                <FormControlLabel value="2" control={<Radio />} label="Fair" />
                <FormControlLabel value="1" control={<Radio />} label="Poor" />
            </RadioGroup>}
            {answerType === "RADIO_SELECT_YES_NO" && (<RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={surveyAnswers[question.id].value}
                onChange={(event) => onComponentChange(event, question.id)}
            >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>)}
            {(answerType === "SINGLE_LINE_TEXT" || answerType === "MULTI_LINE_TEXT") &&
                (
                    <TextField
                        value={surveyAnswers[question.id].value}
                        fullWidth
                        onChange={(event) => onComponentChange(event, question.id)}
                        multiline={answerType === "MULTI_LINE_TEXT"}
                        rows={answerType === "MULTI_LINE_TEXT" ? 6 : 1}
                    />
                )}
            <FormHelperText>{surveyErrors[question.id]}</FormHelperText>
        </>);

    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = [];
        const errors = {}
        for (const [key, v] of Object.entries(surveyAnswers)) {
            if (!v.required || !_.isEmpty(v.value.toString())) {
                data.push({
                    id: key,
                    value: v.value.toString()
                });
            } else {
                errors[key] = 'A Value is needed';
            }
        }

        if (_.isEmpty(errors)) {
            try {
                await SurveyApi.addSurveyAnswers(surveyData.id, data);
                setDone(true);
            } catch (err) {
            }
        } else {
            setSurveyErrors(errors);
        }
    }

    if (error) {
        return (<Box display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={"column"}
            minHeight="100vh">
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <Typography variant="h2" gutterBottom>
                        Uh Oh! Something happened!
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        {error === "NOT_FOUND" ? "we couldn't find the survey." : "An error occured while retrieving the survey."}
                    </Typography>
                </CardContent>
            </Card>
        </Box >);
    }

    if (done) {
        return (
            <Box display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh">
                <Card sx={{ minWidth: 275 }} >
                    <CardContent>
                        <Typography variant="h2" gutterBottom>
                            You are all done!
                        </Typography>
                        <Typography variant="h4" component="div">
                            Thank you for your feedback!
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        )
    }

    if (surveyData && surveyAnswers && surveyData.assessmentQuestions) {
        surveyData.assessmentQuestions.sort(function (a, b) { return a.priority - b.priority });
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                flex={1}
            >
                <Stack width={"80%"} spacing={2} padding={10}>
                    <img
                        src={logo}
                        width={192}
                        height={192}
                        style={{ alignSelf: "center" }}
                        alt="Locspect logo"
                    />
                    <h2 style={{ alignSelf: "center" }}>{surveyData.name}</h2>
                    <Divider></Divider>
                    {surveyData.assessmentQuestions.map((question) => (
                        <Card key={question.id} sx={{ width: "100%" }}>
                            <CardContent>
                                <FormControl fullWidth error={!_.isEmpty(surveyErrors[question.id])}>
                                    {getComponent(question)}
                                </FormControl>
                            </CardContent>
                        </Card>
                    ))}
                    <Button variant="contained" onClick={handleSubmit}>Done</Button>
                </Stack>
            </Box>
        );
    }

    return (
        <Box display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh">
            <CircularProgress />
        </Box>
    )
}