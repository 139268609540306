import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import {
  List,
  Datagrid,
  TextField,
  SimpleList,
  downloadCSV,
  Create,
  SimpleForm,
  TextInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  BooleanInput,
  minLength,
  BooleanField,
  ArrayField,
  SimpleShowLayout,
  useTranslate,
  FunctionField,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { DefaultPagination } from '../../pagination/Pagination';

const exporter = (assessments) => {
  const assessmentForExport = assessments.map((assessment) => {
    let assessmentForExport = {};
    assessmentForExport.assessment_name = assessment.name;
    return assessmentForExport;
  });
  jsonExport(
    assessmentForExport,
    {
      headers: ['assessment_name'],
    },
    (err, csv) => {
      downloadCSV(csv, 'assessment_list');
    }
  );
};

const QuestionShow = () => {
  const translate = useTranslate();
  return (
    <SimpleShowLayout>
      <ArrayField fullWidth label="" source="assessmentQuestions">
        <Datagrid bulkActionButtons={false}>
          <TextField fullWidth label="locspect.resource.assessments.fields.question" source="value" />
          <FunctionField fullWidth label="locspect.resource.assessments.fields.answerType.label" render={record => `${translate('locspect.resource.assessments.fields.answerType.' + record.answerType)}`} />
          <BooleanField fullWidth label="locspect.resource.assessments.fields.required" source="required" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  )
};

const answerChoices = [
  { id: 'RADIO_SELECT_5', name: '1 to 5 Rating' },
  { id: 'RADIO_SELECT_YES_NO', name: 'Yes or No' },
  { id: 'CHECK_BOX', name: 'Checkbox' },
  { id: 'SINGLE_LINE_TEXT', name: 'Single line text' },
  { id: 'MULTI_LINE_TEXT', name: 'Multiple line text' },
  { id: 'IMAGES', name: 'Images' },
  { id: 'TEXT_NO_ANSWER', name: 'No answer' },
];

export const AssessmentList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <List perPage={25} pagination={<DefaultPagination />} exporter={exporter}>
      {isSmall ? (
        <SimpleList primaryText={(record) => `${record.name}`} />
      ) : (
        <Datagrid bulkActionButtons={false} expand={QuestionShow}>
        <TextField label="locspect.resource.assessments.fields.assessment" source="name" />
          <TextField label="locspect.resource.assessments.fields.assessment" source="assessmentType" />
        </Datagrid>
      )}
    </List>
  );
};


export const AssessmentCreate = ({assessmentType}) => {

  return (
    <Create transform={v => {
      let idx = 0;
      for (let q of v.questions) {
        q.position = idx++;
      }
      return v
    }}>
      <SimpleForm>
        <TextInput defaultValue={assessmentType} source="assessmentType" label="locspect.resource.assessments.fields.assessmentType" disabled/>
        <TextInput fullWidth label="locspect.resource.assessments.fields.assessment" source="name" validate={[required()]} />
        {/* <BooleanInput fullWidth defaultValue={false} label="locspect.resource.assessments.fields.allowAllCompSites" source="allManagedCompanySites" size='medium' /> */}

        <ArrayInput fullWidth source="questions" label="locspect.resource.assessments.fields.questions" defaultValue={[{}]} >
          <SimpleFormIterator validate={[required()]} fullWidth >
            <TextInput multiline={true} source="question" validate={[required(), minLength(2)]} label="locspect.resource.assessments.fields.question" helperText={false} fullWidth />
            <SelectInput fullWidth validate={[required()]} defaultValue={"RADIO_SELECT_5"} source="answerType" label="locspect.resource.assessments.fields.answerType.label" choices={answerChoices} />
            <BooleanInput fullWidth defaultValue={false} label="locspect.resource.assessments.fields.required" source="required" size='medium' />
            {assessmentType === "INSPECTION" && (<><BooleanInput fullWidth defaultValue={false} label="locspect.resource.assessments.fields.extraDetails" source="extraDetails" size='medium' /></>)}
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
};