import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { FunctionField } from "react-admin";

const DateTimeField = ({ source }) => {
    return <FunctionField
        render={record => {
            const dateTime = _.get(record, source, null);
            return dateTime ? `${moment(dateTime).format('YYYY-MM-DD HH:mm:ss')}` : ""
        }}
    />
};


export default DateTimeField;