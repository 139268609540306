import * as React from 'react';
import { Menu, useResourceDefinitions, useSidebarState, useTranslate } from 'react-admin';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import BadgeIcon from '@mui/icons-material/Badge';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import _ from 'lodash';
import { DefaultAccordion } from '../accordion/AccordionComponent';
import PollIcon from '@mui/icons-material/Poll';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
// import AssignmentIcon from '@mui/icons-material/Assignment';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

export const MyMenu = ({ role }) => {
    const resources = useResourceDefinitions();
    const translate = useTranslate();
    const [open] = useSidebarState();

    const resourcesKeys = {}
    if (!_.isEmpty(resources)) {
        for (const resource of Object.values(resources)) {
            const key = _.get(resource, "options.key", null);
            if (key) {
                resourcesKeys[key] = resource;
            }
        }
    }
    return (
        <Menu>
            {resourcesKeys["employees"] &&
                <DefaultAccordion expanded={true} sx={{ flex: 1 }} disableGutters={true} label={open ? translate("locspect.resource.menu.company") : ""}>
                    <Menu.Item key={"employees"} to={`/users`} primaryText="locspect.resource.employees.label" leftIcon={<BadgeIcon />} disableGutters={true} />
                    <Menu.Item key={"sites"} to={`/sites`} primaryText="locspect.resource.sites.label" leftIcon={<BusinessIcon />} disableGutters={true} />
                    <Menu.Item key={"acknowledgements"} to={`/acknowledgements/results`} primaryText="locspect.resource.assessmentUsercompanyResults.label" leftIcon={<HowToVoteIcon />} disableGutters={true} />
                </DefaultAccordion>}
            {resourcesKeys["surveys"] &&
                <DefaultAccordion expanded={true} sx={{ flex: 1 }} disableGutters={true} label={open ? translate("locspect.resource.assessments.survey") : ""}>
                    <Menu.Item key={"surveys"} to={`/surveys`} primaryText="locspect.resource.assessments.survey" leftIcon={<PollIcon />} disableGutters={true} />
                    <Menu.Item key={"surveysSites"} to={`/surveys/sites`} primaryText="locspect.resource.assessmentSites.label" leftIcon={<WhereToVoteIcon />} disableGutters={true} />
                    <Menu.Item key={"surveysResults"} to={`/surveys/results`} primaryText="locspect.resource.assessmentResults.label" leftIcon={<HowToVoteIcon />} disableGutters={true} />
                </DefaultAccordion>}

            {/* {resourcesKeys["inspections"] &&
                <DefaultAccordion expanded={true} sx={{ flex: 1 }} disableGutters={true} label={open ? "Inspections" : ""}>
                    <Menu.Item key={"inspections"} to={`/inspections`} primaryText="locspect.resource.assessments.inspection" leftIcon={<PollIcon />} disableGutters={true} />
                    <Menu.Item key={"inspectionsSites"} to={`/inspections/sites`} primaryText="locspect.resource.assessmentSites.label" leftIcon={<WhereToVoteIcon />} disableGutters={true} />
                    <Menu.Item key={"inspectionsResults"} to={`/inspections/results`} primaryText="locspect.resource.assessmentResults.label" leftIcon={<HowToVoteIcon />} disableGutters={true} />
                </DefaultAccordion>} */}
            {resourcesKeys["workOrders"] &&
                <DefaultAccordion expanded={true} sx={{ flex: 1 }} disableGutters={true} label={open ? translate("locspect.resource.workOrders.label") : ""}>
                    <Menu.Item key={"workOrders"} to={`/workorders`} primaryText="locspect.resource.workOrders.label" leftIcon={<NoteAltIcon />} disableGutters={true} />
                </DefaultAccordion>
            }
            {resourcesKeys["managedSites"] &&
                <DefaultAccordion expanded={true} sx={{ flex: 1 }} disableGutters={true} label={open ? translate("locspect.resource.menu.managedCompany") : ""}>
                    {role === 'ADMIN' && <Menu.Item key={"managedStatistics"} to={`/managed/company/statistics`} primaryText="locspect.resource.menu.statistics" leftIcon={<EqualizerIcon />} disableGutters={true} />}
                    <Menu.Item key={"managedSites"} to={`/managed/company/sites`} primaryText="locspect.resource.managedSites.label" leftIcon={<BusinessIcon />} disableGutters={true} />
                    <Menu.Item key={"managedJobs"} to={`managed/company/site/jobs`} primaryText="locspect.resource.managedSiteJobs.label" leftIcon={<WorkIcon />} disableGutters={true} />
                    {role === 'ADMIN' && <Menu.Item key={"managedAlerts"} to={`/managed/company/site/alerts`} primaryText="locspect.resource.managedSiteAlerts.label" leftIcon={<NotificationImportantIcon />} disableGutters={true} />}
                </DefaultAccordion>}

            {resourcesKeys["sharedSites"] &&
                <DefaultAccordion expanded={true} sx={{ flex: 1 }} disableGutters={true} label={open ? translate("locspect.resource.menu.sharedCompany") : ""}>
                    <Menu.Item key={"sharedStatistics"} to={`/shared/statistics`} primaryText="locspect.resource.menu.statistics" leftIcon={<EqualizerIcon />} disableGutters={true} />
                    <Menu.Item key={"sharedSites"} to={`/shared/sites`} primaryText="locspect.resource.sharedSites.label" leftIcon={<BusinessIcon />} disableGutters={true} />
                    <Menu.Item key={"sharedJobs"} to={`/shared/site/jobs`} primaryText="locspect.resource.sharedSiteJobs.label" leftIcon={<WorkIcon />} disableGutters={true} />
                </DefaultAccordion>}
        </Menu>
    );
};