import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { FunctionField, useRecordContext } from 'react-admin';

export const getPeriod = (source, record) => {
  const startTimestamp = moment(_.get(record, source, null));
  const hour = startTimestamp.hours();
  if (hour >= 7 && hour < 15) {
    return { period: 'Morning', color: 'gold' };
  } else if (hour >= 15 && hour < 23) {
    return { period: 'Afternoon', color: 'orange' };
  }
  return { period: 'Overnight', color: 'blue' };
};

const StartedPeriodField = ({ source }) => {
  const record = useRecordContext();

  const { period, color } = getPeriod(source, record);
  return <FunctionField sx={{ color: color }} render={(record) => period} />;
};

export default StartedPeriodField;
