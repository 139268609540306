import _ from "lodash";
import * as React from "react";
import { FunctionField } from "react-admin";

const DistanceField = ({ source, source2 }) => {
    return <FunctionField
        render={record => {
            const distance1 = _.get(record, source, null);
            const distance2 = _.get(record, source2, null);
            return `${distance1 ? parseFloat(distance1).toFixed(1) : ""} / ${distance2 ? parseFloat(distance2).toFixed(1) : ""}`
        }
        }
    />
};

export default DistanceField;