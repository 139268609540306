import _ from 'lodash';
import moment from 'moment';

const sum = (arr, key) => {
  if (!_.isEmpty(arr)) {
    return arr.reduce((a, b) => a + (b[key] || 0), 0);
  }
  return 0;
};

export const sortObjectByKeys = (object) => {
  return Object.values(
    Object.keys(object)
      .sort()
      .reduce((obj, key) => {
        obj[key] = object[key];
        return obj;
      }, {})
  );
};

export const extractMapDatafromCompanySiteJobAnalytics = (companySiteJobAnalytics) => {
  const mapData = [];
  const seen = {};
  for (let companySiteJobAnalytic of companySiteJobAnalytics) {
    const companySite = companySiteJobAnalytic.companySite;
    const role = companySiteJobAnalytic.role;
    const seenKey = companySite.id + role;
    if (companySite && !(seenKey in seen)) {
      seen[seenKey] = true;
      mapData.push({
        id: companySite.id,
        name: companySite.site.siteName,
        role: role,
        loc: [
          companySite.site.geoLocation.latitude,
          companySite.site.geoLocation.longitude,
        ],
      });
    }
  }

  return mapData;
};

export const createLineChartData = (startDate, endDate, defaultValues) => {
  const lineChartData = {};
  let startDateTmp = moment(startDate);
  while (!startDateTmp.isAfter(endDate)) {
    const keyDate = moment(startDateTmp).format('YYYY-MM-DD');
    lineChartData[keyDate] = {
      name: keyDate,
      ...defaultValues,
    };
    startDateTmp = startDateTmp.add(1, 'days');
  }
  return lineChartData;
};

const createCharAndAvg = (arr, key, lineChartData, keyLabel, dayCount) => {
  let avgCnt = 0;
  for (const element of arr) {
    avgCnt += element[key];
    const keyDate = moment(element.createdAt).format('YYYY-MM-DD');
    lineChartData[keyDate] = {
      ...lineChartData[keyDate],
      [keyLabel]:
        lineChartData[keyDate] && lineChartData[keyDate][keyLabel]
          ? lineChartData[keyDate][keyLabel] + element[key]
          : element[key],
    };
  }
  return avgCnt / dayCount;
};

export const calculateUserCompanyAnalytics = (
  userCompanyAnalytics,
  lineChartData,
  dayCount,
  endDate
) => {
  const userData = [];
  const userAvgData = [];
  if (!_.isEmpty(userCompanyAnalytics)) {
    const now = moment(endDate).startOf('day');
    const filterByCreatedDate = (arr) =>
      arr.filter(({ createdAt }) => moment(createdAt).isSame(now));
    const yesterdayUsers = filterByCreatedDate(userCompanyAnalytics);

    let totalUsers = !_.isEmpty(userCompanyAnalytics) ? userCompanyAnalytics[0].totalUsers : 0;
    let totalActiveUsers = sum(yesterdayUsers, 'totalActiveUsers');

    const userLabel = totalActiveUsers + '/' + totalUsers;
    
    if (totalActiveUsers === 0 && totalUsers === 0) {
      userData.push({ label: userLabel, name: 'Active Users', value: 1 });
    } else {
      userData.push({ label: userLabel, name: 'Total Users', value: totalUsers - totalActiveUsers });
      userData.push({ label: userLabel, name: 'Active Users', value: totalActiveUsers });
    }

    const userAvgCount = createCharAndAvg(
      userCompanyAnalytics,
      'totalActiveUsers',
      lineChartData,
      'Active Users',
      dayCount
    );
    userAvgData.push({
      label: '' + userAvgCount.toFixed(2),
      name: 'Active Users',
      value: userAvgCount,
    });
  } else {
    userData.push({ label: '0', name: 'Total Users', value: 1 });
    userAvgData.push({ label: '0', name: 'Active Users', value: 1 });
  }
  return { userData, userAvgData };
};

export const calculateCompanySiteAnalytics = (
  companySiteAnalytics,
  lineChartData,
  dayCount,
  endDate,
  totalSites
) => {
  const companySiteData = [];
  const companyAvgSiteData = [];
  if (!_.isEmpty(companySiteAnalytics)) {
    const now = moment(endDate).startOf('day');
    const filterByCreatedDate = (arr) =>
      arr.filter(({ createdAt }) => moment(createdAt).isSame(now));
    const yesterdaySites = filterByCreatedDate(companySiteAnalytics);

    let totalActiveSites = sum(yesterdaySites, 'totalActiveSites');

    const companySiteLabel = totalActiveSites + '/' + totalSites;
    companySiteData.push({
      label: companySiteLabel,
      name: 'Total Sites',
      value: totalSites - totalActiveSites,
    });
    companySiteData.push({
      label: companySiteLabel,
      name: 'Active Sites',
      value: totalActiveSites,
    });

    const companyAvgActiveSiteCount = createCharAndAvg(
      companySiteAnalytics,
      'totalActiveSites',
      lineChartData,
      'Active Sites',
      dayCount
    );
    companyAvgSiteData.push({
      label: '' + companyAvgActiveSiteCount.toFixed(2),
      name: 'Active Sites',
      value: companyAvgActiveSiteCount,
    });
  } else {
    companySiteData.push({ label: 0  + '/' + totalSites, name: 'Total Sites', value: totalSites });
    companyAvgSiteData.push({ label: 0 , name: 'Active Sites', value: 0 });
  }
  return { companySiteData, companyAvgSiteData };
};

export const calculateCompanySiteJobAnalytics = (
  companySiteJobAnalytics,
  lineChartData,
  dayCount,
  endDate
) => {
  const companySiteJobData = [];
  const companyAvgSiteJobData = [];
  let yesterdayJobs = [];
  if (!_.isEmpty(companySiteJobAnalytics)) {
    const now = moment(endDate).startOf('day');
    const filterByCreatedDate = (arr) =>
      arr.filter(({ createdAt }) => moment(createdAt).isSame(now));
    yesterdayJobs = filterByCreatedDate(companySiteJobAnalytics);

    let companySiteJobCount = sum(yesterdayJobs, 'totalJob');
    companySiteJobData.push({
      label: '' + companySiteJobCount,
      name: 'Total Jobs',
      value: companySiteJobCount === 0 ? 1 : companySiteJobCount,
    });

    const companyAvgSiteJobCount = createCharAndAvg(
      companySiteJobAnalytics,
      'totalJob',
      lineChartData,
      'Jobs',
      dayCount
    );
    companyAvgSiteJobData.push({
      label: '' + companyAvgSiteJobCount.toFixed(2),
      name: 'Total Jobs',
      value: companyAvgSiteJobCount === 0 ? 1 : companyAvgSiteJobCount,
    });
  } else {
    companySiteJobData.push({ label: '0', name: 'Total Jobs', value: 1 });
    companyAvgSiteJobData.push({ label: '0', name: 'Total Jobs', value: 1 });
  }
  return { companySiteJobData, companyAvgSiteJobData };
};
