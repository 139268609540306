import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import {
  List,
  TextField,
  SimpleList,
  downloadCSV,
  SimpleForm,
  ReferenceInput,
  Create,
  AutocompleteInput,
  DatagridConfigurable,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { DefaultPagination } from '../../pagination/Pagination';
import SurveyQRCodeButton from '../../button/SurveyQRCodeButton';
import { ENDPOINTS } from '../../api/JsonDataProvider';
import { FieldWrapper, FullListActions } from '../../actions/ListActions';

const exporter = (assessments) => {
  const assessmentForExport = assessments.map((assessment) => {
    let assessmentForExport = {};
    assessmentForExport.name = assessment.assessment.name;
    assessmentForExport.company_name = assessment.companySite.site.company.companyName;
    assessmentForExport.site_code = assessment.companySite.site.siteCode;
    assessmentForExport.site_name = assessment.companySite.site.siteName;
    assessmentForExport.street = assessment.companySite.site.address.street;
    assessmentForExport.city = assessment.companySite.site.address.city;
    assessmentForExport.state = assessment.companySite.site.address.state;
    assessmentForExport.country = assessment.companySite.site.address.country;
    assessmentForExport.zip = assessment.companySite.site.address.zip;
    return assessmentForExport;
  });
  jsonExport(
    assessmentForExport,
    {
      headers: ['name', 'company_name', 'site_code', 'site_name', 'street', 'city', 'country', 'zip'],
    },
    (err, csv) => {
      downloadCSV(csv, 'assessment_site_list');
    }
  );
};

export const AssessmentSiteList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  
  return (
    <List actions={<FullListActions />} perPage={25} pagination={<DefaultPagination />} exporter={exporter} filters={[]}>
      {isSmall ? (
        <SimpleList primaryText={(record) => `${record.assessment.name}`} />
      ) : (
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField label="locspect.resource.assessmentSites.fields.assessment" source="assessment.name" />
          <TextField label="locspect.resource.assessmentSites.fields.companyName" source="companySite.site.company.companyName" />
          <TextField label="locspect.resource.assessmentSites.fields.code" source="companySite.site.siteCode" />
          <TextField label="locspect.resource.assessmentSites.fields.siteName" source="companySite.site.siteName" />
          <TextField label="locspect.resource.assessmentSites.fields.street" source="companySite.site.address.street" />
          <TextField label="locspect.resource.assessmentSites.fields.city" source="companySite.site.address.city" />
          <TextField label="locspect.resource.assessmentSites.fields.state" source="companySite.site.address.state" />
          <TextField label="locspect.resource.assessmentSites.fields.country" source="companySite.site.address.country" />
          <TextField label="locspect.resource.assessmentSites.fields.zip" source="companySite.site.address.zip" />
          <FieldWrapper label="QR">
            <SurveyQRCodeButton />
          </FieldWrapper>
        </DatagridConfigurable>
      )}
    </List>
  );
};

const siteFilterToQuery = searchText => ( searchText ? { 'site.siteName_like': `${searchText}` } : {});
const assessmentFilterToQuery = searchText => ( searchText ? { 'name_like': `${searchText}` } : {});

export const AssessmentSiteCreate = ({assessmentLabel, assessmentReference, companySiteReference=ENDPOINTS['managed/company/sites'].name, comppanySiteLabel="locspect.resource.assessmentSites.fields.managedSite" }) => {
  return (
    <Create>
      <SimpleForm>
        <ReferenceInput source="assessmentId" label={assessmentLabel} reference={assessmentReference} perPage={1000}>
          <AutocompleteInput filterToQuery={assessmentFilterToQuery} fullWidth />
        </ReferenceInput>
        <ReferenceInput 
          source="companySiteId" 
          label={comppanySiteLabel} 
          reference={companySiteReference}
          enableGetChoices={({ q }) => q && q.length >= 2}
          >
            <AutocompleteInput filterToQuery={siteFilterToQuery}  fullWidth />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
};