import moment from 'moment';
import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import {
  List,
  TextField,
  SimpleList,
  downloadCSV,
  TextInput,
  DateTimeInput,
  EditButton,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  useResourceDefinition,
  DatagridConfigurable,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { DefaultPagination } from '../../pagination/Pagination';
import _ from 'lodash';
import DateField from '../../field/DateField';
import TimeField from '../../field/TimeField';
import DurationField from '../../field/DurationField';
import DistanceField from '../../field/DistanceField';
import NameField from '../../field/NameField';
import StartedPeriodField, { getPeriod } from '../../field/StartedPeriodField';
import { DefaultListActions, FieldWrapper } from '../../actions/ListActions';

const exporter = (jobs) => {
  const jobsForExport = jobs.map((job) => {
    let jobForExport = {};
    jobForExport.site_code = job.companySite.site.siteCode;
    jobForExport.state = job.companySite.site.address.state;
    jobForExport.country = job.companySite.site.address.country;
    jobForExport.site_name = job.companySite.site.siteName;
    jobForExport.company_name = job.companySite.site.company.companyName;
    jobForExport.start_date = moment(job.startTimestamp).format('YYYY-MM-DD');
    jobForExport.start_time = moment(job.startTimestamp).format('HH:mm:ss');
    jobForExport.end_date = job.endTimestamp ? moment(job.endTimestamp).format('YYYY-MM-DD') : '';
    jobForExport.end_time = job.endTimestamp ? moment(job.endTimestamp).format('HH:mm:ss') : '';
    jobForExport.duration = job.endTimestamp
      ? moment
        .duration(moment(job.endTimestamp).diff(moment(job.startTimestamp)))
        .asHours()
        .toFixed(2) + ' Hrs'
      : '';
    jobForExport.period = getPeriod('startTimestamp', job);
    jobForExport.distance =
      (job.startDistance ? parseFloat(job.startDistance).toFixed(1) : '') +
      '/' +
      (job.endDistance ? parseFloat(job.endDistance).toFixed(1) : '');
    jobForExport.employee_name =
      job.userCompany.user.firstName + ' ' + job.userCompany.user.lastName;
    jobForExport.role = job.role ? job.role : '';
    return jobForExport;
  });
  jsonExport(
    jobsForExport,
    {
      headers: [
        'site_code',
        'state',
        'country',
        'site_name',
        'company_name',
        'start_date',
        'start_time',
        'end_date',
        'end_time',
        'duration',
        'period',
        'distance',
        'employee_name',
        'role',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'company_site_job_list');
    }
  );
};

const filter = [
  <TextInput
    source="companySite->site->siteCode_like"
    label="locspect.resource.managedSiteJobs.fields.code"
  />,
  <TextInput
    source="companySite->site->address->state_like"
    label="locspect.resource.managedSiteJobs.fields.state"
  />,
  <TextInput
    source="companySite->site->address->country_like"
    label="locspect.resource.managedSiteJobs.fields.country"
  />,
  <TextInput
    source="companySite->site->siteName_like"
    label="locspect.resource.managedSiteJobs.fields.siteName"
  />,
  <TextInput
    source="companySite->site->company->companyName_like"
    label="locspect.resource.managedSiteJobs.fields.companyName"
  />,
  <DateTimeInput
    source="startTimestamp_ge"
    label="locspect.resource.managedSiteJobs.fields.startTimeStart"
  />,
  <DateTimeInput
    source="startTimestamp_le"
    label="locspect.resource.managedSiteJobs.fields.startTimeEnd"
  />,
  <DateTimeInput
    source="endTimestamp_ge"
    label="locspect.resource.managedSiteJobs.fields.endTimeStart"
  />,
  <DateTimeInput
    source="endTimestamp_le"
    label="locspect.resource.managedSiteJobs.fields.endTimeEnd"
  />,
  <TextInput
    source="userCompany->user->firstName_like"
    label="locspect.resource.managedSiteJobs.fields.firstName"
  />,
  <TextInput
    source="userCompany->user->lastName_like"
    label="locspect.resource.managedSiteJobs.fields.lastName"
  />,
  <TextInput
    source="role_like"
    label="locspect.resource.managedSiteJobs.fields.role"
  />,
];

export const ManagedCompanyJobList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  let resourceDefinition = useResourceDefinition();
  const role = _.get(resourceDefinition, 'options.role', null);
  return (
    <List
      actions={<DefaultListActions />}
      perPage={25}
      pagination={<DefaultPagination />}
      sort={{ field: 'endTimestamp', order: 'DESC' }}
      exporter={exporter}
      filters={filter}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) =>
            `${record.companySite.site.siteCode} | ${record.companySite.site.company.companyName}`
          }
          secondaryText={(record) => `${record.companySite.site.siteName}`}
          tertiaryText={(record) =>
            `${record.endTimestamp
              ? new Date(record.endTimestamp).toLocaleString()
              : new Date(record.startTimestamp).toLocaleString()
            }`
          }
        />
      ) : (
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField
            label="locspect.resource.managedSiteJobs.fields.code"
            source="companySite.site.siteCode"
          />
          <TextField
            label="locspect.resource.managedSiteJobs.fields.state"
            source="companySite.site.address.state"
          />
          <TextField
            label="locspect.resource.managedSiteJobs.fields.country"
            source="companySite.site.address.country"
          />
          <TextField
            label="locspect.resource.managedSiteJobs.fields.siteName"
            source="companySite.site.siteName"
          />
          <TextField
            label="locspect.resource.managedSiteJobs.fields.companyName"
            source="companySite.site.company.companyName"
          />
          <DateField
            label="locspect.resource.managedSiteJobs.fields.startDate"
            source="startTimestamp"
            sortBy="startTimestamp"
          />
          <TimeField
            label="locspect.resource.managedSiteJobs.fields.startTime"
            source="startTimestamp"
            sortable={false}
          />
          <DateField
            label="locspect.resource.managedSiteJobs.fields.endDate"
            source="endTimestamp"
            sortBy="endTimestamp"
          />
          <TimeField
            label="locspect.resource.managedSiteJobs.fields.endTime"
            source="endTimestamp"
            sortable={false}
          />
          <DurationField
            label="locspect.resource.managedSiteJobs.fields.duration"
            source="endTimestamp"
            source2="startTimestamp"
            sortable={false}
          />
          <StartedPeriodField
            label="locspect.resource.managedSiteJobs.fields.period"
            source="startTimestamp"
            sortable={false}
          />
          <DistanceField
            label="locspect.resource.managedSiteJobs.fields.distance"
            source="startDistance"
            source2="endDistance"
            sortable={false}
          />
          <NameField
            label="locspect.resource.managedSiteJobs.fields.employeeName"
            source="userCompany.user.firstName"
            source2="userCompany.user.lastName"
            sortBy="userCompany.user.firstName"
          />
          ;
          <TextField
            label="locspect.resource.managedSiteJobs.fields.role"
            source="role"
          />
          {role === 'ADMIN' &&
            <FieldWrapper label="ra.action.edit">
              <EditButton />
            </FieldWrapper>
          }
        </DatagridConfigurable>
      )}
    </List>
  );
};

const ManagedCompanyJobEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const ManagedCompanyJobEdit = () => (
  <Edit undoable={false}>
    <SimpleForm toolbar={<ManagedCompanyJobEditToolbar />}>
      <TextInput
        disabled
        label="locspect.resource.managedSiteJobs.fields.code"
        source="companySite.site.siteCode"
      />
      <TextInput
        disabled
        label="locspect.resource.managedSiteJobs.fields.state"
        source="companySite.site.address.state"
      />
      <TextInput
        disabled
        label="locspect.resource.managedSiteJobs.fields.country"
        source="companySite.site.address.country"
      />
      <TextInput
        disabled
        label="locspect.resource.managedSiteJobs.fields.siteName"
        source="companySite.site.siteName"
      />
      <TextInput
        disabled
        label="locspect.resource.managedSiteJobs.fields.companyName"
        source="companySite.site.company.companyName"
      />
      <TextInput
        disabled
        label="locspect.resource.managedSiteJobs.fields.role"
        source="role"
      />
      <TextInput
        disabled
        label="locspect.resource.managedSiteJobs.fields.eRailSafe"
        source="userCompany.erailSafe"
      />
      <DateTimeInput
        label="locspect.resource.managedSiteJobs.fields.startTime"
        source="startTimestamp"
      />
      <DateTimeInput
        label="locspect.resource.managedSiteJobs.fields.endTime"
        source="endTimestamp"
      />
    </SimpleForm>
  </Edit>
);
