import moment from "moment";
import * as React from "react";
import { useMediaQuery } from '@mui/material';
import { List, Datagrid, TextField, SimpleList, downloadCSV, TextInput, DateTimeInput } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { DefaultPagination } from "../../pagination/Pagination";

const exporter = alerts => {
    const alertsForExport = alerts.map(alert => {
        let alertForExport = {};
        alertForExport.site_code = alert.companySiteFrequency.companySite.site.siteCode;
        alertForExport.state = alert.companySiteFrequency.companySite.site.address.state;
        alertForExport.country = alert.companySiteFrequency.companySite.site.address.country;
        alertForExport.site_name = alert.companySiteFrequency.companySite.site.siteName;
        alertForExport.company_name = alert.companySiteFrequency.companySite.site.company.companyName;
        alertForExport.status = alert.companySiteAlertStatus.status;
        alertForExport.alert_date = moment(alert.alertDate).format("YYYY-MM-DD");
        return alertForExport;
    });
    jsonExport(alertsForExport, {
        headers: ['site_code', 'state', 'country', 'site_name', 'company_name', 'status', 'alert_date']
    }, (err, csv) => {
        downloadCSV(csv, 'company_site_alert_list');
    });
};

const filter = [
    <TextInput label="locspect.resource.managedSiteAlerts.fields.code" source="companySiteFrequency->companySite->site->siteCode_like" />,
    <TextInput label="locspect.resource.managedSiteAlerts.fields.state" source="companySiteFrequency->companySite->site->address->state_like" />,
    <TextInput label="locspect.resource.managedSiteAlerts.fields.country" source="companySiteFrequency->companySite->site->address->country_like" />,
    <TextInput label="locspect.resource.managedSiteAlerts.fields.siteName" source="companySiteFrequency->companySite->site->siteName_like" />,
    <TextInput label="locspect.resource.managedSiteAlerts.fields.companyName" source="companySiteFrequency->companySite->site->company->companyName_like" />,
    <TextInput label="locspect.resource.managedSiteAlerts.fields.status" source="companySiteAlertStatus->status_like" />,
    <DateTimeInput label="locspect.resource.managedSiteAlerts.fields.alertDateStart" source="alertDate_ge" />,
    <DateTimeInput label="locspect.resource.managedSiteAlerts.fields.alertDateEnd" source="alertDate_le" />
]

export const ManagedCompanyAlertList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            perPage={25}
            pagination={<DefaultPagination />}
            sort={{ field: 'alertDate', order: 'DESC' }}
            exporter={exporter}
            filters={filter} >
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${record.companySiteFrequency.companySite.site.siteCode} | ${record.companySiteFrequency.companySite.site.company.companyName}`}
                    secondaryText={record => `${record.companySiteFrequency.companySite.site.siteName}`}
                    tertiaryText={record => `${record.companySiteAlertStatus.status} ${moment(record.alertDate).format("YYYY-MM-DD")}`}
                />
            ) : (
                <Datagrid bulkActionButtons={false}>
                    <TextField label="locspect.resource.managedSiteAlerts.fields.code" source="companySiteFrequency.companySite.site.siteCode" />
                    <TextField label="locspect.resource.managedSiteAlerts.fields.state" source="companySiteFrequency.companySite.site.address.state" />
                    <TextField label="locspect.resource.managedSiteAlerts.fields.country" source="companySiteFrequency.companySite.site.address.country" />
                    <TextField label="locspect.resource.managedSiteAlerts.fields.siteName" source="companySiteFrequency.companySite.site.siteName" />
                    <TextField label="locspect.resource.managedSiteAlerts.fields.companyName" source="companySiteFrequency.companySite.site.company.companyName" />
                    <TextField label="locspect.resource.managedSiteAlerts.fields.status" source="companySiteAlertStatus.status" />
                    <TextField label="locspect.resource.managedSiteAlerts.fields.alertDate" source="alertDate" />
                </Datagrid>
            )}
        </List>
    )
};