import * as React from 'react';
import { Layout } from 'react-admin';
import MyAppBar from './MyAppBar';
import { MyMenu } from './MyMenu';

const MyLayout = (props) => {
  const {
    managedcompanies,
    setmanagedcompany,
    managedcompany,
    sharedcompanies,
    setsharedcompany,
    sharedcompany,
    userCompanies,
    setUserCompany,
    userCompany,
    role,
    ...others
  } = props;
  return (
    <Layout
    sx={{
      '& .RaLayout-appFrame': {
        marginTop: 0,
      },
    }}
      {...others}
      appBar={() => <MyAppBar {...props}></MyAppBar>}
      menu={() => <MyMenu role={role}></MyMenu>}
    />
  );
};

export default MyLayout;
