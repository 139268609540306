/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of dataprovider decoration.
 */
export const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file.rawFile);
    });

export const convertImages = async (documents, moment) => {
    if (documents) {
        const docs = [];
        for (let document of documents) {
            if (document.id) {
                docs.push({ id: document.id, document: document.document, name: document.name, type: document.type, moment: moment });
            } else if(document.doc) {
                docs.push(document.doc);
            } else {
                const doc = await convertFileToBase64(document)
                docs.push({ document: doc, name: document.title, type: document.rawFile.type, moment: moment });
            }
        }
        return docs;
    }
    return undefined;
}