import { ReactKeycloakProvider } from "@react-keycloak/web";
import * as React from "react";
import { keycloak } from "./config/config";
import MainView from "./screen/MainScreen";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SurveyForm from "./survey/SurveyForm";

const theme = createTheme();

const App = () => {
    if (window.location.pathname.startsWith("/surveys")) {
        return (
            <SurveyForm />
        )
    }
    return (
        <ThemeProvider theme={theme}>
            <ReactKeycloakProvider
                autoRefreshToken={true}
                authClient={keycloak}
                initOptions={{ onLoad: 'login-required' }} >
                <MainView />
            </ReactKeycloakProvider>
        </ThemeProvider>
    )
}

export default App;