import { CreateButton, ExportButton, FilterButton, SelectColumnsButton, TopToolbar } from "react-admin";

export const FullListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

export const DefaultListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
  );

export  const FieldWrapper = ({ children, label }) => children;
