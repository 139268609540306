import Keycloak from "keycloak-js";

export const LOCSPECT_API_URL = process.env.REACT_APP_LOCSPECT_API_URL ? process.env.REACT_APP_LOCSPECT_API_URL :  "http://localhost:8080";

export const keycloak = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_AUTH_URL ? process.env.REACT_APP_KEYCLOAK_AUTH_URL : "http://localhost:8180/auth",
    realm:  process.env.REACT_APP_KEYCLOAK_REALM ? process.env.REACT_APP_KEYCLOAK_REALM : 'locspect',
    clientId:  process.env.REACT_APP_KEYCLOAK_CLIENT_ID ? process.env.REACT_APP_KEYCLOAK_CLIENT_ID : 'locspect_web'
});

export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS ? process.env.REACT_APP_SENTRY_DNS : "";
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT ? process.env.REACT_APP_SENTRY_ENVIRONMENT : "dev";

export const REACT_APP_VERSION = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : "dev";
export const REACT_APP_NAME = process.env.REACT_APP_NAME ? process.env.REACT_APP_NAME : "dev";