import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import {
  List,
  TextField,
  SimpleList,
  downloadCSV,
  TextInput,
  EditButton,
  Edit,
  SimpleForm,
  Create,
  SelectInput,
  Toolbar,
  SaveButton,
  useResourceDefinition,
  useEditContext,
  required,
  minLength,
  choices,
  regex,
  minValue,
  maxValue,
  maxLength,
  NumberInput,
  DatagridConfigurable,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { DefaultPagination } from '../../pagination/Pagination';
import QRCodeButton from '../../button/QRCodeButton';
import { COUNTRIES, STATES } from '../../config/locations';
import _ from 'lodash';
import { postcodeValidator } from 'postcode-validator';
import { FieldWrapper, FullListActions } from '../../actions/ListActions';

const exporter = (sites) => {
  const sitesForExport = sites.map((site) => {
    let siteForExport = {};
    siteForExport.site_code = site.siteCode;
    siteForExport.site_name = site.siteName;
    siteForExport.street = site.address.street;
    siteForExport.city = site.address.city;
    siteForExport.state = site.address.state;
    siteForExport.country = site.address.country;
    siteForExport.zip_code = site.address.zip;
    siteForExport.latitude = site.geoLocation.latitude;
    siteForExport.longitude = site.geoLocation.longitude;
    return siteForExport;
  });
  jsonExport(
    sitesForExport,
    {
      headers: ['site_code', 'site_name', 'street', 'city', 'state', 'country', 'zip_code', 'latitude', 'longitude'],
    },
    (err, csv) => {
      downloadCSV(csv, 'company_site_list');
    }
  );
};

const filter = [
  <TextInput source="siteCode_like" label="locspect.resource.sites.fields.code" />,
  <TextInput source="siteName_like" label="locspect.resource.sites.fields.siteName" />,
  <TextInput source="address->street_like" label="locspect.resource.sites.fields.street" />,
  <TextInput source="address->city_like" label="locspect.resource.sites.fields.city" />,
  <TextInput source="address->state_like" label="locspect.resource.sites.fields.state" />,
  <TextInput source="address->country_like" label="locspect.resource.sites.fields.country" />,
  <TextInput source="address->zip_like" label="locspect.resource.sites.fields.zip" />,
];

export const CompanySiteList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <List actions={<FullListActions />} perPage={25} pagination={<DefaultPagination />} exporter={exporter} filters={filter}>
      {isSmall ? (
        <SimpleList primaryText={(record) => `${record.siteCode} | ${record.siteName}`} />
      ) : (
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField label="locspect.resource.sites.fields.code" source="siteCode" />
          <TextField label="locspect.resource.sites.fields.siteName" source="siteName" />
          <TextField label="locspect.resource.sites.fields.street" source="address.street" />
          <TextField label="locspect.resource.sites.fields.city" source="address.city" />
          <TextField label="locspect.resource.sites.fields.state" source="address.state" />
          <TextField label="locspect.resource.sites.fields.country" source="address.country" />
          <TextField label="locspect.resource.sites.fields.zip" source="address.zip" />
          <TextField
            label="locspect.resource.sites.fields.latitude"
            source="geoLocation.latitude"
          />
          <TextField
            label="locspect.resource.sites.fields.longitude"
            source="geoLocation.longitude"
          />
          <FieldWrapper label="QR">
            <QRCodeButton />
          </FieldWrapper>
          <FieldWrapper label="ra.action.edit">
            <EditButton />
          </FieldWrapper>
        </DatagridConfigurable>
      )}
    </List>
  );
};

const CompanySiteToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const CompanySiteCreate = () => {
  let resourceDefinition = useResourceDefinition();
  const company = resourceDefinition.options.company;

  const transform = (data) => ({
    id: data.id,
    companyId: company.id,
    siteName: data.siteName,
    siteCode: data.siteCode,
    latitude: data.geoLocation.latitude,
    longitude: data.geoLocation.longitude,
    street: data.address.street,
    city: data.address.city,
    state: data.address.state,
    country: data.address.country,
    zipCode: data.address.zip,
  });

  return (
    <Create resource="site" transform={transform} redirect="/sites" mutationMode="pessimistic">
      <CompanySiteForm />
    </Create>
  );
};

export const CompanySiteEdit = () => {
  const transform = (data) => ({
    id: data.id,
    siteName: data.siteName,
    siteCode: data.siteCode,
    latitude: data.geoLocation.latitude,
    longitude: data.geoLocation.longitude,
    street: data.address.street,
    city: data.address.city,
    state: data.address.state,
    country: data.address.country,
    zipCode: data.address.zip,
  });

  return (
    <Edit resource="site" transform={transform} redirect="/sites" mutationMode="pessimistic">
      <CompanySiteForm />
    </Edit>
  );
};

export const CompanySiteForm = () => {
  const { record } = useEditContext();
  const [country, setCountry] = React.useState(record ? record.address.country : 'CA');
  let selectCountries = [];
  let selectStates = [];
  for (const [key, value] of Object.entries(COUNTRIES)) {
    selectCountries.push({
      id: key,
      name: value,
    });
  }
  if (!_.isEmpty(country) && country in STATES) {
    for (const [key, value] of Object.entries(STATES[country])) {
      selectStates.push({
        id: key,
        name: value,
      });
    }
  }

  const parseFloatValue = (value) => {
    try {
      const nbr = parseFloat(value);
      if (!isNaN(nbr)) {
        return nbr;
      }
    } catch (err) { }
    return null;
  };

  return (
    <SimpleForm toolbar={<CompanySiteToolbar />}>
      <TextInput
        label="locspect.resource.sites.fields.code"
        source="siteCode"
        validate={[
          required('The site code is required'),
          regex(
            /^[a-zA-Z0-9_-]+$/,
            'Site Code is invalid only letters, numbers, "_", and "-" are allowed'
          ),
        ]}
      />
      <TextInput
        label="locspect.resource.sites.fields.siteName"
        source="siteName"
        validate={[
          required('The site name is required'),
          minLength(1, 'The minimum length should be 1'),
          maxLength(512, 'The maximum length should be 512'),
        ]}
      />
      <TextInput
        label="locspect.resource.sites.fields.street"
        source="address.street"
        validate={[
          required('The street is required'),
          minLength(1, 'The minimum length should be 1'),
          maxLength(512, 'The maximum length should be 512'),
        ]}
      />
      <TextInput
        label="locspect.resource.sites.fields.city"
        source="address.city"
        validate={[
          required('The city is required'),
          minLength(1, 'The minimum length should be 1'),
          maxLength(256, 'The maximum length should be 256'),
        ]}
      />
      <SelectInput
        allowEmpty={false}
        label="locspect.resource.sites.fields.country"
        source="address.country"
        choices={selectCountries}
        onChange={(event) => {
          setCountry(event.target.value);
        }}
        validate={[
          required('The country is required'),
          choices(Object.keys(COUNTRIES), 'Please choose one of the values'),
        ]}
      />
      <SelectInput
        disabled={_.isEmpty(country)}
        label="locspect.resource.sites.fields.state"
        source="address.state"
        choices={selectStates}
        defaultValue={undefined}
        validate={[
          required('The state is required'),
          choices(Object.keys(STATES[country]), 'Please choose one of the values'),
        ]}
      />
      <TextInput
        disabled={_.isEmpty(country)}
        label="locspect.resource.sites.fields.zip"
        source="address.zip"
        validate={[
          required('The zip is required'),
          (value) =>
            postcodeValidator(value, country) ? undefined : 'Code is invalid for the country',
        ]}
      />
      <NumberInput
        label="locspect.resource.sites.fields.latitude"
        source="geoLocation.latitude"
        validate={[
          required('The latitude is required'),
          minValue(-90, "Latitude can't be less than -90"),
          maxValue(90, "Latitude can't be more than 90"),
        ]}
        parse={parseFloatValue}
      />
      <NumberInput
        label="locspect.resource.sites.fields.longitude"
        source="geoLocation.longitude"
        validate={[
          required('The longitude is required'),
          minValue(-180, "Longitude can't be less than -180"),
          maxValue(180, "Longitude can't be more than 180"),
        ]}
        parse={parseFloatValue}
      />
    </SimpleForm>
  );
};
