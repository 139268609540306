import moment from 'moment';
import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import {
  List,
  DatagridConfigurable,
  TextField,
  SimpleList,
  downloadCSV,
  DateTimeInput,
  TextInput,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { DefaultPagination } from '../../pagination/Pagination';
import DateField from '../../field/DateField';
import TimeField from '../../field/TimeField';
import DurationField from '../../field/DurationField';
import StartedPeriodField, { getPeriod } from '../../field/StartedPeriodField';
import { DefaultListActions } from '../../actions/ListActions';

const exporter = (jobs) => {
  const jobsForExport = jobs.map((job) => {
    let jobForExport = {};
    jobForExport.site_code = job.companySite.site.siteCode;
    jobForExport.state = job.companySite.site.address.state;
    jobForExport.country = job.companySite.site.address.country;
    jobForExport.site_name = job.companySite.site.siteName;
    jobForExport.company_name = job.userCompany.company.companyName;
    jobForExport.start_date = moment(job.startTimestamp).format('YYYY-MM-DD');
    jobForExport.start_time = moment(job.startTimestamp).format('HH:mm:ss');
    jobForExport.end_date = job.endTimestamp ? moment(job.endTimestamp).format('YYYY-MM-DD') : '';
    jobForExport.end_time = job.endTimestamp ? moment(job.endTimestamp).format('HH:mm:ss') : '';
    jobForExport.period = getPeriod('startTimestamp', job);
    jobForExport.duration = job.endTimestamp
      ? moment
          .duration(moment(job.endTimestamp).diff(moment(job.startTimestamp)))
          .asHours()
          .toFixed(2) + ' Hrs'
      : '';
    jobForExport.role = job.role ? job.role : '';
    return jobForExport;
  });
  jsonExport(
    jobsForExport,
    {
      headers: [
        'site_code',
        'state',
        'country',
        'site_name',
        'company_name',
        'start_date',
        'start_time',
        'end_date',
        'end_time',
        'period',
        'duration',
        'role',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'company_shared_site_job_list');
    }
  );
};

const filter = [
  <TextInput
    source="companySite->site->siteCode_like"
    label="locspect.resource.sharedSiteJobs.fields.code"
  />,
  <TextInput
    source="companySite->site->address->state_like"
    label="locspect.resource.sharedSiteJobs.fields.state"
  />,
  <TextInput
    source="companySite->site->address->country_like"
    label="locspect.resource.sharedSiteJobs.fields.country"
  />,
  <TextInput
    source="companySite->site->siteName_like"
    label="locspect.resource.sharedSiteJobs.fields.siteName"
  />,
  <TextInput
    source="userCompany->company->companyName_like"
    label="locspect.resource.sharedSiteJobs.fields.companyName"
  />,
  <DateTimeInput
    source="startTimestamp_ge"
    label="locspect.resource.sharedSiteJobs.fields.startTimeStart"
  />,
  <DateTimeInput
    source="startTimestamp_le"
    label="locspect.resource.sharedSiteJobs.fields.startTimeEnd"
  />,
  <DateTimeInput
    source="endTimestamp_ge"
    label="locspect.resource.sharedSiteJobs.fields.endTimeStart"
  />,
  <DateTimeInput
    source="endTimestamp_le"
    label="locspect.resource.sharedSiteJobs.fields.endTimeEnd"
  />,
  <TextInput
    source="role_like"
    label="locspect.resource.sharedSiteJobs.fields.role"
  />,
];

export const CompanySharedJobList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<DefaultListActions />}
      perPage={25}
      pagination={<DefaultPagination />}
      sort={{ field: 'endTimestamp', order: 'DESC' }}
      exporter={exporter}
      filters={filter}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) =>
            `${record.companySite.site.siteCode} | ${record.userCompany.company.companyName}`
          }
          secondaryText={(record) => `${record.companySite.site.siteName}`}
          tertiaryText={(record) =>
            `${
              record.endTimestamp
                ? new Date(record.endTimestamp).toLocaleString()
                : new Date(record.startTimestamp).toLocaleString()
            }`
          }
        />
      ) : (
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField
            label="locspect.resource.sharedSiteJobs.fields.code"
            source="companySite.site.siteCode"
          />
          <TextField
            label="locspect.resource.sharedSiteJobs.fields.state"
            source="companySite.site.address.state"
          />
          <TextField
            label="locspect.resource.sharedSiteJobs.fields.country"
            source="companySite.site.address.country"
          />
          <TextField
            label="locspect.resource.sharedSiteJobs.fields.siteName"
            source="companySite.site.siteName"
          />
          <TextField
            label="locspect.resource.sharedSiteJobs.fields.companyName"
            source="userCompany.company.companyName"
          />
          <DateField
            label="locspect.resource.sharedSiteJobs.fields.startDate"
            source="startTimestamp"
            sortBy="startTimestamp"
          />
          <TimeField
            label="locspect.resource.sharedSiteJobs.fields.startTime"
            source="startTimestamp"
            sortable={false}
          />
          <DateField
            label="locspect.resource.sharedSiteJobs.fields.endDate"
            source="endTimestamp"
            sortBy="endTimestamp"
          />
          <TimeField
            label="locspect.resource.sharedSiteJobs.fields.endTime"
            source="endTimestamp"
            sortable={false}
          />
          <StartedPeriodField
            label="locspect.resource.sharedSiteJobs.fields.period"
            source="startTimestamp"
            sortable={false}
          />
          <DurationField
            label="locspect.resource.sharedSiteJobs.fields.duration"
            source="endTimestamp"
            source2="startTimestamp"
            sortable={false}
          />
          <TextField
            label="locspect.resource.sharedSiteJobs.fields.role"
            source="role"
          />
        </DatagridConfigurable>
      )}
    </List>
  );
};
