import React, { useState, useEffect, useCallback } from 'react';
import { useDataProvider, downloadCSV } from 'react-admin';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import jsonExport from 'jsonexport/dist';
import DownloadIcon from '@mui/icons-material/GetApp';
import { DefaultAccordion } from '../accordion/AccordionComponent';
import { ObjectSelectFormField } from '../field/form/SelectFormField';

const fetchData = async (dataProvider, startDate, endDate, endpoint, extraFilter = {}) => {
    return dataProvider.getList(
        endpoint,
        {
            filter: { ...extraFilter, "createdAt_ge": startDate, "createdAt_le": endDate },
            sort: { field: 'createdAt', order: 'DESC' },
            pagination: { page: 1, perPage: 25000 },
        }
    );
}

const exporter = data => {
    const datasForExport = data.map(e => {
        let dataForExport = {};
        dataForExport.site_code = e.siteCode;
        dataForExport.site_name = e.siteName;
        dataForExport.total = e.total;
        dataForExport.type = e.informationType;
        return dataForExport;
    });
    jsonExport(datasForExport, {
        headers: ['site_code', 'site_name', 'total', 'type']
    }, (err, csv) => {
        downloadCSV(csv, 'job_extra_information');
    });
};

const JobExtraInformationTab = () => {
    const [jobInformation, setJobInformationAnalytics] = useState([]);
    const [jobInformationType, setJobInformationType] = useState("TOTAL_ROOMS_CLEANED");

    const [dateRange, setDateRange] = useState({
        createdAtStart: moment().startOf('day').subtract(31, "days"),
        createdAtEnd: moment().startOf('day').subtract(1, "days")
    })

    const dataProvider = useDataProvider();

    const createdAtStartString = dateRange.createdAtStart.format("YYYY-MM-DD");
    const createdAtEndString = dateRange.createdAtEnd.format("YYYY-MM-DD");

    const fetchJobInformationAnalytics = useCallback(async () => {
        let extraFilter = {
            "informationType.informationType_eq": jobInformationType
        };

        const { data } = await fetchData(dataProvider, createdAtStartString, createdAtEndString, 'companyJobInformationAnalytics', extraFilter);
        const jobInformationDetails = {};
        if (jobInformationType === "TOTAL_ROOMS_CLEANED") {
            for (let info of data) {
                const siteCode = info.companySite.site.siteCode;
                if (!(siteCode in jobInformationDetails)) {
                    jobInformationDetails[siteCode] = {
                        siteCode: info.companySite.site.siteCode,
                        siteName: info.companySite.site.siteName,
                        informationType: info.informationType.informationType,
                        total: 0
                    };
                }
                jobInformationDetails[siteCode].total += parseInt(info.value);
            }
        }
        setJobInformationAnalytics(Object.values(jobInformationDetails));
    }, [dataProvider, createdAtStartString, createdAtEndString, jobInformationType]);


    useEffect(() => {
        fetchJobInformationAnalytics();
    }, [createdAtStartString, createdAtEndString, jobInformationType]); // eslint-disable-line react-hooks/exhaustive-deps


    const classes = useStyles();

    return (
        <Box>
            <Grid container>
                <Grid container justifyContent="flex-end">
                    <DefaultAccordion expanded={false} label={"Filters"}>
                        <Grid container>
                            <Grid item>
                                <ObjectSelectFormField selectedId={jobInformationType} valueChanged={(value) => setJobInformationType(value)} values={{ "TOTAL_ROOMS_CLEANED": "Total rooms cleaned" }} label="Action" emptyValue={false} />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <TextField
                                    id="startDate"
                                    label="Start date"
                                    type="date"
                                    defaultValue={dateRange.createdAtStart.format("YYYY-MM-DD")}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ max: dateRange.createdAtEnd.format("YYYY-MM-DD") }}
                                    onChange={(event) => setDateRange({ ...dateRange, createdAtStart: moment(event.target.value) })}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="endDate"
                                    label="End Date"
                                    type="date"
                                    defaultValue={dateRange.createdAtEnd.format("YYYY-MM-DD")}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ max: moment().startOf("day").subtract(1, "days").format("YYYY-MM-DD") }}
                                    onChange={(event) => setDateRange({ ...dateRange, createdAtEnd: moment(event.target.value) })}
                                />
                            </Grid>
                        </Grid>
                    </DefaultAccordion>
                </Grid>
                <Grid container justifyContent="flex-end" marginTop={2}><Button height={40} color="primary" onClick={() => exporter(jobInformation)} label="Export"><DownloadIcon /> Export</Button></Grid>
                <Grid container item>
                    <Grid item xs sm md lg>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Site Code</TableCell>
                                        <TableCell align="left">Site Name</TableCell>
                                        <TableCell align="left">Total</TableCell>
                                        <TableCell align="left">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {jobInformation.map((row) => (
                                        <TableRow key={row.siteCode}>
                                            <TableCell component="th" scope="row">{row.siteCode}</TableCell>
                                            <TableCell align="left">{row.siteName}</TableCell>
                                            <TableCell align="left">{row.total}</TableCell>
                                            <TableCell align="left">Total rooms cleaned</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    );
};

const useStyles = makeStyles((theme) => ({
    textField: {
        alignSelf: 'center',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: 200
    }
}));

export default JobExtraInformationTab;