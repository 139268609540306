import * as React from "react";
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    formControl: {
        alignSelf: 'center',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: 200
    },
    formControlSelect: {
        color: 'white',
        height: 40,
        marginRight: theme.spacing(1)
    },
}));

export const ArrayOfObjectSelectFormField = ({ selectedId, valueChanged, values, label, valueKey, emptyValue = true }) => {
    const classes = useStyles();
    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel style={{ color: "white" }}>{label}</InputLabel>
            <Select
                native
                value={selectedId}
                onChange={(event) => {
                    const value = event.target.value;
                    valueChanged(!_.isEmpty(value) ? values.find(element => element.id === value) : null);
                }}
                label={label}
                className={classes.formControlSelect}
                style={{ color: 'white' }} >
                {emptyValue && <option key="NONE" value=""></option>}
                {values && values.map((value, index) => (
                    <option key={value.id} value={value.id}>{_.get(value, valueKey, "")}</option>
                ))}
            </Select>
        </FormControl>);
};

export const ObjectSelectFormField = ({ selectedId, valueChanged, values, label, emptyValue = true }) => {
    const classes = useStyles();
    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>{label}</InputLabel>
            <Select
                native
                value={selectedId}
                onChange={(event) => valueChanged(event.target.value)}
                label={label}
            >
                {emptyValue && <option key="NONE" value=""></option>}
                {Object.keys(values).map((key, index) => (
                    <option key={key} value={key}>{values[key]}</option>
                ))}
            </Select>
        </FormControl>);
};
