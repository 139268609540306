import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { makeStyles } from '@mui/styles';
import { Cell, Pie, PieChart, Sector } from 'recharts';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: 'center',
  },
}));

const PieCard = (props: Props) => {
  const { data = [], title = '' } = props;
  const [activeState, setActiveState] = React.useState(0);
  const classes = useStyles();

  return (
    <Box display="inline-block">
      <Card className={classes.root}>
        <CardHeader title={title} />
        <PieChart width={400} height={200}>
          <Pie
            activeIndex={activeState}
            activeShape={renderActiveShape}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={(_, index) => {
              setActiveState(index);
            }}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </Card>
    </Box>
  );
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.label}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export default PieCard;
