import { fetchUtils } from 'ra-core';

export const ENDPOINTS = {
  users: {
    name: 'users',
    url: 'v1/companies/{companyId}/users',
  },
  usersCompaniesSites: {
    name: 'usersCompaniesSites',
    url: 'v1/companies/{companyId}/users/{userCompanyId}/companies/sites',
  },
  usersCompaniesSitesDelete: {
    name: 'usersCompaniesSitesDelete',
    url: 'v1/companies/{companyId}/users/{userCompanyId}/companies/sites/{siteId}',
  },
  sites: {
    name: 'sites',
    url: 'v1/companies/{companyId}/sites',
  },
  workOrders: {
    name: 'workOrders',
    url: 'v1/companies/{companyId}/workorders'
  },
  surveys: {
    name: 'surveys',
    url: 'v1/companies/{companyId}/assessments',
    filter: {
      'assessmentType_eq': 'SURVEY',
    },
  },
  "surveys/sites": {
    name: 'surveys/sites',
    url: 'v1/companies/{companyId}/assessments/sites',
    filter: {
      'assessment.assessmentType_eq': 'SURVEY',
    },
  },
  'acknowledgements/results': {
    name: 'acknowledgements/results',
    url: 'v1/companies/{companyId}/assessments/results',
    params: {
      type: 'ACKNOWLEDGEMENT',
    },
    filter: {
      'assessment.assessmentType_eq': 'ACKNOWLEDGEMENT',
    },
  },
  'surveys/results': {
    name: 'surveys/results',
    url: 'v1/companies/{companyId}/assessments/results',
    filter: {
      'assessmentSite.assessment.assessmentType_eq': 'SURVEY',
    },
  },
  inspections: {
    name: 'inspections',
    url: 'v1/companies/{companyId}/assessments',
    filter: {
      'assessmentType_eq': 'INSPECTION',
    },
  },
  "inspections/sites": {
    name: 'inspections/sites',
    url: 'v1/companies/{companyId}/assessments/sites',
    filter: {
      'assessment.assessmentType_eq': 'INSPECTION',
    },
  },
  'inspections/results': {
    name: 'inspections/results',
    url: 'v1/companies/{companyId}/assessments/results',
    filter: {
      'assessmentSite.assessment.assessmentType_eq': 'INSPECTION',
    },
  },
  site: {
    name: 'site',
    url: 'v1/sites',
  },
  'shared/sites': {
    name: 'shared/sites',
    url: 'v1/companies/{companyId}/sites',
    params: {
      type: 'shared',
    },
    filter: {
      'company.id_eq': '{sharedCompanyId}',
    },
  },
  'shared/site/jobs': {
    name: 'shared/site/jobs',
    url: 'v1/companies/{companyId}/sites/jobs',
    params: {
      type: 'shared',
    },
    filter: {
      'userCompany.company.id_eq': '{sharedCompanyId}',
    },
  },
  'managed/company/sites': {
    name: 'managed/company/sites',
    url: 'v1/companies/{companyId}/sites',
    params: {
      type: 'managed',
    },
    filter: {
      'site.company.id_eq': '{managedCompanyId}',
    },
  },
  'managed/company/sites/min': {
    name: 'managed/company/sites/min',
    url: 'v1/companies/{companyId}/sites',
    params: {
      type: 'managed',
      minimal: true,
    }
  },
  'managed/company/site/jobs': {
    name: 'managed/company/site/jobs',
    url: 'v1/companies/{companyId}/sites/jobs',
    params: {
      type: 'managed',
    },
    filter: {
      'companySite.site.company.id_eq': '{managedCompanyId}',
    },
  },
  'managed/company/site/alerts': {
    name: 'managed/company/site/alerts',
    url: 'v1/companies/{companyId}/sites/alerts',
    params: {
      type: 'managed',
    },
    filter: {
      'companySiteFrequency.companySite.site.company.id_eq': '{managedCompanyId}',
    },
  },
  companySiteAnalytics: {
    name: 'companySiteAnalytics',
    url: 'v1/analytics/companies/{companyId}/sites',
    params: {
      type: 'managed',
    },
    filter: {
      'site.company.id_eq': '{managedCompanyId}',
    },
  },
  companySiteJobAnalytics: {
    name: 'companySiteJobAnalytics',
    url: 'v1/analytics/companies/{companyId}/sites/jobs',
    filter: {
      'companySite.site.company.id_eq': '{managedCompanyId}',
    },
  },
  companyStateAnalytics: {
    name: 'companyStateAnalytics',
    url: 'v1/analytics/companies/{companyId}/states',
    filter: {
      'managedCompany.id_eq': '{managedCompanyId}',
    },
  },
  companyJobInformationAnalytics: {
    name: 'companyJobInformationAnalytics',
    url: 'v1/analytics/companies/{companyId}/jobs/informations',
    filter: {
      'companySite.site.company.id_eq': '{managedCompanyId}',
    },
  },
  sharedCompanySiteAnalytics: {
    name: 'sharedCompanySiteAnalytics',
    url: 'v1/analytics/companies/{companyId}/sites',
    params: {
      type: 'shared',
    },
    filter: {
      'company.id_eq': '{sharedCompanyId}',
    },
  },
  sharedCompanySiteJobAnalytics: {
    name: 'companySiteJobAnalytics',
    url: 'v1/analytics/companies/{companyId}/sites/jobs',
    params: {
      type: 'shared',
    },
    filter: {
      'companySite.company.id_eq': '{sharedCompanyId}',
    },
  },
  sharedCompanyStateAnalytics: {
    name: 'companyStateAnalytics',
    url: 'v1/analytics/companies/{companyId}/states',
    params: {
      type: 'shared',
    },
    filter: {
      'company.id_eq': '{sharedCompanyId}',
    },
  },
};
/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts?id=123&id=456&id=789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 */
const JsonDataProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const config = ENDPOINTS[resource];

    const filterParams = {};

    Object.keys(params.filter || {}).forEach((key) => {
      if (key.includes('->')) {
        filterParams[`${key.replaceAll('->', '.')}`] = params.filter[key];
      } else {
        filterParams[`${key}`] = params.filter[key];
      }
    });

    Object.keys(config.filter || {}).forEach((key) => {
      if(!(key in filterParams)) {
        filterParams[`${key}`] = config.filter[key];
      }
    });

    let query = {
      sort: field + ',' + order,
      page: page - 1,
      size: perPage,
    };

    const url = `${apiUrl}/${config.url}`;
    return httpClient(url, {
      query: query,
      filters: filterParams,
      configs: config.params,
      perPage: perPage,
    })
      .then(({ json }) => {
        return {
          data: json.content,
          total: json.totalElements,
        };
      })
  },

  getOne: (resource, params) => {
    return httpClient(`${apiUrl}/${ENDPOINTS[resource].url}/${params.id}`, {})
      .then(({ json }) => ({
        data: json,
      }))
  },

  getMany: (resource, params) => {
    const config = ENDPOINTS[resource];

    const filterParams = {};

    Object.keys(params.filter || {}).forEach((key) => {
      if (key.includes('->')) {
        filterParams[`${key.replaceAll('->', '.')}`] = params.filter[key];
      } else {
        filterParams[`${key}`] = params.filter[key];
      }
    });

    Object.keys(config.filter || {}).forEach((key) => {
      filterParams[`${key}`] = config.filter[key];
    });

    const url = `${apiUrl}/${config.url}`;

    return httpClient(url, {
      filters: filterParams,
      configs: config.params,
    })
      .then(({ json }) => {
        return {
          data: json.content,
          total: json.totalElements,
        };
      })
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const config = ENDPOINTS[resource];

    const filterParams = {};

    Object.keys(params.filter || {}).forEach((key) => {
      if (key.includes('->')) {
        filterParams[`${key.replaceAll('->', '.')}`] = params.filter[key];
      } else {
        filterParams[`${key}`] = params.filter[key];
      }
    });

    Object.keys(config.filter || {}).forEach((key) => {
      filterParams[`${key}`] = config.filter[key];
    });

    let query = {
      sort: field + ',' + order,
      page: page - 1,
      size: perPage,
    };

    let url = `${apiUrl}/${config.url}`;

    if (params.target) {
      url = url.replace('{' + params.target + '}', params.id);
    }

    return httpClient(url, {
      query: query,
      filters: filterParams,
      configs: config.params,
      perPage: perPage,
    }).then(({ json }) => {
      return {
        data: json.content,
        total: json.totalElements,
      };
    });
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${ENDPOINTS[resource].url}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      return { data: json };
    }),

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${ENDPOINTS[resource].url}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) => {
    let url = `${apiUrl}/${ENDPOINTS[resource].url}`;

    if (params.meta && params.meta.target) {
      url = url.replace('{' + params.meta.target + '}', params.meta.id);
    }

    if (params.metas) {
      for (let meta of params.metas) {
        url = url.replace('{' + meta.target + '}', meta.id);
      }
    }

    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json ? json.id : '' },
    }));
  },

  delete: (resource, params) => {
    let url = `${apiUrl}/${ENDPOINTS[resource].url}`;

    if (params.meta) {
      if (params.meta.target) {
        url = url.replace('{' + params.meta.target + '}', params.meta.id);
      }

      if (params.meta.substitutes) {
        for (let substitute of params.meta.substitutes) {
          url = url.replace('{' + substitute.target + '}', substitute.id);
        }
      }
    }

    if (params.id) {
      url += `/${params.id}`
    }
    return httpClient(url, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  },

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${ENDPOINTS[resource].url}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),
});

export default JsonDataProvider;
